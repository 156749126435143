import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const faqsSection = () => {
    return (
        <>
            <div className="main-sec">
                <div className="container">
                    <div className="row">
                        

                    <div className="col-md-8">
                    <div className="abt-info">
                    <h3 className="abt-title">FAQ's</h3>
                            <MDBAccordion initialActive={1} className='mb-4'>
                                <MDBAccordionItem collapseId={1} headerTitle='1. What is the EJ Data Action Portal?'>
                                  <p>The <strong>EJ Data Action Portal (EJDAP)</strong> elegantly merges distinct, rich databases that present data (via reports, graphs, maps, etc.) in a simple, easily accessible, and usable format.</p>
                                  <p><strong>EJ DAP displays data that provides pollution types and sources including:</strong></p>
                                  <ul>
                                    <li>Location of polluting sources, such as an industrial facility</li>
                                    <li>All the chemical substances that originate from specific facilities</li>
                                    <li>The quantity of chemical substances each source emits</li>
                                    <li>Toxicity information on approximately 152 toxic chemicals through the Pharos database</li>
                                  </ul>
                                  <p>The EJDAP is interactive. It allows users to easily run queries using their zip code and conduct research that is relevant to the specific needs of their communities. This research can then be used to facilitate communication and collaboration between other impacted community members, local governmental officials, and industry. The EJ DAP leverages an extensive array of existing organizational partnerships, and deep institutional knowledge and competencies in the EJ sector.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={2} headerTitle='2. What types of data are available on the EJ Data Action Portal? '>
                                  <p><strong>The portal contains a wide range of data related to environmental justice, including:</strong></p>
                                  <ul>
                                    <li>Demographic data</li>
                                    <li>Pollution levels</li>
                                    <li>Health outcomes</li>
                                    <li>Socioeconomic indicators</li>
                                  </ul>
                                  <p>This comprehensive dataset allows users to examine the intersection of environmental factors and social disparities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={3} headerTitle='3. How can I access the EJ Data Action Portal? '>
                                  <p>Once you arrive at the main website, simply click on <strong>‘Explore Data’</strong> in the main menu or via the button in the header.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={4} headerTitle='4. How do I conduct a search?'>
                                 <ol>
                                    <li>Once inside the portal, enter a Zip Code using the search bar in the header menu. </li>
                                    <li>The multicolored buttons across the top open dashboards that correspond to the specific category of data you are interested in exploring. Click the dashboard category to see currently available qualifying datasets</li>
                                 </ol>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={5} headerTitle='5. How often do you update the data sets?'>
                                 <p>Data is updated regularly to ensure its accuracy and relevance. The frequency of updates may vary depending on the source and availability of new information. Users can refer to the metadata accompanying each dataset for information on the last update.</p> 
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={6} headerTitle='6. What is CEJST data? '>
                                 <p><strong>Council on Environmental Quality</strong> (CEQ) to develop a geospatial mapping tool to identify disadvantaged communities that face burdens. The tool has an interactive map and uses datasets that are indicators of burdens.</p>
                                 <p>Federal agencies will use the tool for the Justice40 Initiative. It will help them identify disadvantaged communities that should receive 40% of the overall benefits of programs included in the Justice40 Initiative. The Justice40 Initiative seeks to deliver 40% of the overall benefits in climate, clean energy, and other related areas to disadvantaged communities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={7} headerTitle='7. How do I interpret CEJST data?'>
                                 <p>A community is highlighted as disadvantaged on the CEJST map if it is in a census tract that is</p>
                                 <ol>
                                    <li>at or above the threshold for one or more environmental, climate, or other burdens, and</li>
                                    <li>at or above the threshold for an associated socioeconomic burden.</li>
                                 </ol>
                                 <p>The CEJST does not use racial demographic data as an indicator to help identify disadvantaged communities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={8} headerTitle='8. How is CEJST data different from EJ screen?'>
                                 <p><strong>EJ Screen</strong> is an environmental justice mapping and screening tool that provides the EPA with a nationally consistent dataset and approach for combining environmental and demographic indicators. In contrast, the <strong>CEJST</strong> was specifically developed to provide a uniform, whole-of-government definition of disadvantaged communities for Federal agencies to target Justice40 investment benefits. <strong>EJ Screen</strong> and <strong>CEJST</strong> complement each other – the former provides a tool to screen for environmental and social demographic differences at the community level, while the latter defines, and maps disadvantaged communities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={9} headerTitle='9. What is Toxic Release Inventory (TRI)?'>
                                 <p>TRI tracks the management of certain toxic chemicals that may pose a threat to human health and the environment. U.S. facilities in different industry sectors must report annually how much of each chemical is released to the environment and/or managed through recycling, energy recovery, and treatment. There are currently 770 individually listed chemicals and 33 chemical categories covered by the TRI Program. Facilities that manufacture, process or otherwise use these chemicals in amounts above established levels must submit annual reporting forms for each chemical.</p>
                                 <p>In general, <Link to="https://www.epa.gov/toxics-release-inventory-tri-program/tri-listed-chemicals" target='_blank'><u>chemicals covered by the TRI Program</u></Link> are those that cause:</p>
                                 <ul>
                                    <li>Cancer or other chronic human health effects</li>
                                    <li>Significant adverse acute human health effects</li>
                                    <li>Significant adverse environmental effects</li>
                                 </ul>
                                 <Link to="#" target='_blank'><u>More info <MDBIcon fas icon="angle-double-right" /></u></Link>

                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={10} headerTitle='10. What industries are included in TRI?'>
                                 <p>Facilities that report to TRI are typically larger facilities involved in manufacturing, metal mining, electric power generation, chemical manufacturing, and hazardous waste treatment. Not all industry sectors are covered by the TRI Program, and not all facilities in the covered sectors are required to report to TRI.</p>
                                 <p>For the first phase of our website, we will be only considering air emissions from these covered facilities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={11} headerTitle='11. What is the measuring unit for TRI emission data?'>
                                 <p><strong>Pounds </strong>or <strong>LBS.</strong></p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={12} headerTitle='12. What are Greenhouse Gases?'>
                                 <p>Greenhouse gases (also known as GHGs) are gases in the earth's atmosphere that trap heat. The main contributors for Greenhouse gases are:</p>
                                 <ul>
                                    <li>Carbon dioxide,</li>
                                    <li>Methane,</li>
                                    <li>Nitrous oxide</li>
                                    <li>Fluorinated gases.</li>
                                 </ul>
                                 <Link to="#" target='_blank'><u>More info <MDBIcon fas icon="angle-double-right" /></u></Link>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={13} headerTitle='13. What is the Greenhouse Gas Reporting Program?'>
                                 <p>The purpose of the <strong>GHGRP</strong> is to provide accurate and timely greenhouse has (GHG) data to inform the public, policy makers and other interested parties. The data will help the public better understand emissions from specific industries and individual facilities, factors that influence GHG emission rates, and actions that facilities could take to reduce emissions. In general, facilities that directly emit 25,000 metric tons of carbon dioxide equivalent or more per year are required to submit annual reports to EPA.</p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={14} headerTitle='14. What is the measuring unit for GHG emission data?'>
                                 <p><strong>Metric Tons </strong></p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={15} headerTitle='15. What is Environmental Justice?'>
                                 <p>Environmental justice is the fair treatment and meaningful involvement of all people, regardless of race, color, national origin, or income, to develop, implement, and enforce environmental laws, regulations, and policies. This goal will be achieved when everyone enjoys the same degree of protection from environmental and health hazards, and equal access to the decision-making process to live, learn, and work in a healthy environment.</p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={16} headerTitle='16. What is Social Vulnerability?'>
                                 <p>Every community must prepare for and respond to hazardous events, whether a natural disaster like a tornado or disease outbreak, or a human-made event such as a harmful chemical spill. Social vulnerability refers to a community’s ability to respond to these events. Social vulnerability is measured using factors including poverty, lack of access to transportation, and crowded housing that may weaken a community’s ability to prevent human suffering and financial loss in a disaster.</p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={17} headerTitle='17. What is Social Vulnerability Index ?'>
                                 <p>SVI is a percentile-based index of a county-level vulnerability to disaster. The tool was created to identify and map communities that need the most support during emergencies like hurricanes or disease outbreaks.</p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={18} headerTitle='18. What is EJI?'>
                                 <p>The EJI tool is designed to measure the cumulative impacts of environmental burden through the lens of human health and health equity. The EJI ranks each census tract on 36 environmental, social, and health factors and groups them into three overarching modules and ten different domains. We are considering the score of each of the three modules; social vulnerability, environmental burden, and health vulnerability. The EJI score will allow us to identify the following:</p>
                                 <ul>
                                    <li>Identify and prioritize areas that may require special attention or additional action to improve health and health equity,</li>
                                    <li>Educate and inform the public about their community,</li>
                                    <li>Analyze the unique, local factors driving cumulative impacts on health to inform policy and decision-making, and</li>
                                    <li>Establish meaningful goals and measure progress toward environmental justice and health equity.</li>
                                 </ul>
                                 <Link to="#" target='_blank'><u>More info <MDBIcon fas icon="angle-double-right" /></u></Link>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={19} headerTitle='19. How do I interpret EJI?'>
                                 <p>A percentile ranking represents the proportion of tracts (or counties) that are equal to or lower than a tract of interest in environmental burden. For example, an EJI ranking of 0.85 signifies that 85% of tracts in the nation likely experience less severe cumulative impacts from environmental burden than the tract of interest, and that 15% of tracts in the nation likely experience more severe cumulative impacts from environmental burden.</p>
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={20} headerTitle='20. How do I save data?'>
                                 <p>The data can be saved or printed in a PDF format. You can click on the PDF icon <MDBIcon fas icon="file-export" /> on the left-hand-side corner of the map window. Select the specific year for the particular dashboard from the dropdown menu and click on print. This will download the pdf file to your computer. We are using 11x17 paper size to save the data. Larger paper sizes might affect the pixels.</p>
                                </MDBAccordionItem>
                                   
                                   </MDBAccordion>

                    </div>
                   
                    
                    </div>
                    <div className="col-md-4">
                        <div className="abt-info">
                         <img className="img-fluid rounded" src="./images/faqs-banner.svg" alt="EJ Data Action Portal Faqs" />
                        </div>
                    </div>
                  
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default faqsSection
