import React, { useState } from 'react'
import { MDBBtn, MDBIcon, MDBInputGroup } from 'mdb-react-ui-kit';
import { Icon } from '@iconify/react';
import './StickyMenu.css'

const StickyMenu = (props) => {
    const { mapRef, chartRef, tableRef } = props;
    const [isRightNavOpen, setIsRightNavOpen] = useState(false);
    const [cboTerm, setCboTerm] = useState('');

    const handleScroll = (ref) => {
        if (!ref) return;
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }

    const handleRightNav = () => {
        setIsRightNavOpen(!isRightNavOpen);
    }

    const doCBOSearch = (e) => {
        e.preventDefault();
    }

    return (
        <>
        {/* <div className='sticky-menu'>
            <ul>
                <li>
                    <MDBBtn color='link' size='sm' variant='default' 
                        onClick={()=>{handleScroll(mapRef)}}>
                        <Icon icon="uiw:map" width="2em" height="2em" />
                        <span>Map View</span>
                    </MDBBtn>
                </li>
                <li>
                    <MDBBtn color='link' size='sm' variant='default' 
                        onClick={()=>{handleScroll(chartRef)}}>
                        <Icon icon="healthicons:chart-line" width="2rem" height="2rem"  />
                        <span>Trends</span>
                    </MDBBtn>
                </li>
                <li><MDBBtn color='link' size='sm' variant='default' 
                        onClick={()=>{handleScroll(tableRef)}}>
                    <Icon icon="mdi-light:table" width="2rem" height="2rem" />
                    <span>List View2</span>
                </MDBBtn></li>
                {/* <li><MDBBtn color='link' variant='default'
                    onClick={handleRightNav}>
                    <MDBIcon fas icon="users" size='2x' className='me-0' />
                    <span>CBOs</span>
                </MDBBtn></li> 
            </ul>
            <div className={`right-nav-container  ${isRightNavOpen ? 'open' : ''}`}>
                <div className='action-btn'>
                    <MDBBtn outline color='danger' size='sm' className='rounded-circle text-danger' onClick={handleRightNav}>
                        <MDBIcon fas icon="times" size='2x' className='me-0 text-danger' />
                    </MDBBtn>
                </div>
                <div className='right-nav p-2'>
                    <div className='right-nav-header'>
                        <form onSubmit={doCBOSearch} >
                            <MDBInputGroup className='mb-3'>
                                <input 
                                    className='form-control' 
                                    placeholder="Search" 
                                    type='text' value={cboTerm} 
                                    onChange={(e)=> setCboTerm(e.target.value)} 
                                    onKeyUp={(e)=> setCboTerm(e.target.value)} />
                                <MDBBtn outline type='submit'>
                                    <MDBIcon fas icon="search" />
                                </MDBBtn>
                            </MDBInputGroup>
                        </form>
                    </div>
                    <div className={`right-nav-body`}>
                        <ul>
                            <li>Organization 1</li>
                            <li>Organization 2</li>
                            <li>Organization 3</li>
                            <li>Organization 4</li>
                            <li>Organization 5</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div> */}

<div className="sticky-container">
<ul className="sticky">
    <li onClick={()=>{handleScroll(mapRef)}}> 
        <Icon icon="uiw:map" width="1.2em" height="1.2em" />
        <p>MAP VIEW</p>
    </li>
    <li onClick={()=>{handleScroll(chartRef)}}> 
        <Icon icon="healthicons:chart-line" width="1.2em" height="1.2em"  />
        <p>TRENDS</p>
    </li>
    <li onClick={()=>{handleScroll(tableRef)}}> 
        <Icon icon="mdi-light:table" width="1.2em" height="1.2em" />
        <p>LIST VIEW</p>
    </li>
</ul>
</div>
</>
    )
}

export default StickyMenu
