import React, { useEffect } from 'react'
import CircularProgress from '../progress/Circular'
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, selectTokenTimeStamp } from '../../../redux/authSlice';
import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router-dom';
import { selectLastEventTime } from '../../../redux/userSlice';

const LandingPage = () => {

    const token$ = useSelector(selectToken);
    const history = createBrowserHistory();
    const navgate = useNavigate();

    useEffect(() => {
        // console.log("token", token$);
        if (token$) {
            navgate("/app");
        } else {
            // history.push("/home");
            // window.location.replace("/home");
            navgate("/home");
        }
        // console.log("history", history.location.pathname)
    }, [token$]);

    return (
        <>
            <div className='blur-bg fit-to-block'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                }}
            ><CircularProgress  Width="100px" Height="100px"  /></div>
        </>
    )
}

export default LandingPage
