import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const QnAComponent = () => {
    return (
        <>
            <div className="main-sec " id="qna">
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-8 align-items-center">
                        <div className="col-lg-5 order-lg-2">
                            <figure className="text-center pad-50">
                                <img src="../images/faq-ban.png"
                                    className="img-fluid" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-7">
                            {/* <span className="section-title__tagline">Question</span> */}
                            <h3 className="abt-title">FAQ's</h3>
                            <MDBAccordion initialActive={1} className='mb-4'>
                                <MDBAccordionItem collapseId={1} headerTitle='1. What is the EJ Data Action Portal?'>
                                  <p>The <strong>EJ Data Action Portal (EJ DAP)</strong> elegantly merges distinct, rich databases that present data (via reports, graphs, maps, etc.) in a simple, easily accessible, and usable format.</p>
                                  <p><strong>EJ DAP displays data that provides pollution types and sources including:</strong></p>
                                  <ul>
                                    <li>Location of polluting sources, such as an industrial facility</li>
                                    <li>All the chemical substances that originate from specific facilities</li>
                                    <li>The quantity of chemical substances each source emits</li>
                                    <li>Toxicity information on approximately 152 toxic chemicals through the Pharos database</li>
                                  </ul>
                                  <p>The EJ DAP is interactive. It allows users to easily run queries using their zip code and conduct research that is relevant to the specific needs of their communities. This research can then be used to facilitate communication and collaboration between other impacted community members, local governmental officials, and industry. The EJ DAP leverages an extensive array of existing organizational partnerships, and deep institutional knowledge and competencies in the EJ sector.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={2} headerTitle='2. What types of data are available on the EJ Data Action Portal? '>
                                  <p><strong>The portal contains a wide range of data related to environmental justice, including:</strong></p>
                                  <ul>
                                    <li>Demographic data</li>
                                    <li>Pollution levels</li>
                                    <li>Health outcomes</li>
                                    <li>Socioeconomic indicators</li>
                                  </ul>
                                  <p>This comprehensive dataset allows users to examine the intersection of environmental factors and social disparities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={3} headerTitle='3. How can I access the EJ Data Action Portal? '>
                                  <p>Once you arrive at the main website, simply click on <strong>‘Explore Data’</strong> in the main menu or via the button in the header.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={4} headerTitle='4. How do I conduct a search?'>
                                 <ol>
                                    <li>Once inside the portal, enter a Zip Code using the search bar in the header menu. </li>
                                    <li>The multicolored buttons across the top open dashboards that correspond to the specific category of data you are interested in exploring. Click the dashboard category to see currently available qualifying datasets</li>
                                 </ol>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={5} headerTitle='5. How often do you update the data sets?'>
                                 <p>Data is updated regularly to ensure its accuracy and relevance. The frequency of updates may vary depending on the source and availability of new information. Users can refer to the metadata accompanying each dataset for information on the last update.</p> 
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={6} headerTitle='6. What is CEJST data? '>
                                 <p><strong>Council on Environmental Quality</strong> (CEQ) to develop a geospatial mapping tool to identify disadvantaged communities that face burdens. The tool has an interactive map and uses datasets that are indicators of burdens.</p>
                                 <p>Federal agencies will use the tool for the Justice40 Initiative. It will help them identify disadvantaged communities that should receive 40% of the overall benefits of programs included in the Justice40 Initiative. The Justice40 Initiative seeks to deliver 40% of the overall benefits in climate, clean energy, and other related areas to disadvantaged communities.</p>
                                </MDBAccordionItem>
                                <MDBAccordionItem collapseId={7} headerTitle='7. How do I interpret CEJST data?'>
                                 <p>A community is highlighted as disadvantaged on the CEJST map if it is in a census tract that is</p>
                                 <ol>
                                    <li>at or above the threshold for one or more environmental, climate, or other burdens, and</li>
                                    <li>at or above the threshold for an associated socioeconomic burden.</li>
                                 </ol>
                                 <p>The CEJST does not use racial demographic data as an indicator to help identify disadvantaged communities.</p>
                                </MDBAccordionItem>
                                   
                                   </MDBAccordion>

                                   <Link to="/faqs">Read more FAQ's <MDBIcon fas icon="angle-double-right" /></Link>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default QnAComponent
