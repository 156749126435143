import React, { useEffect } from 'react'
import CircularProgress from '../../common/progress/Circular'
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
import { useNavigate } from 'react-router-dom';
import { setSelectedCBO } from '../../../redux/appSlice';
// import appConfig from '../../../configs/app.config';

const Logout = () => {
    const userData = useSelector(selectTokenData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedCBO(null));
        sessionStorage.removeItem('ejdh_app_state');
        setTimeout(() => {
            // window.location.replace("/");
            navigate('/home');
        }, 1000);
        setTimeout(() => {
            dispatch(logout())
        }, 1000);
    },[])
    return (
        <div className="loading">
            <CircularProgress />
            <h5>
                Logging out...
            </h5>
        </div>
    )
}

export default Logout
