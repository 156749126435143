import appConfig from "../configs/App.config";
import DataNormalizer from "./dataNormalizer";

const CommonServoces = {
    getTrendsData: (zipCode, tab,userType) => {
        let url = appConfig.trendsApiUrls[tab.toLowerCase()][userType].replace('{zip_code}', zipCode) + '&tab=' + tab;
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', "*");
        // return fetch(appConfig.trendsApiUrls[tab.toLowerCase()][userType].replace('{zip_code}', zipCode) + '&tab=' + tab)
        return fetch(url, { headers: headers })
        .then(response => {
            switch (tab) {
                case 'tri':
                    return DataNormalizer.normalizeTRITrendsData(response.json(), tab);
                case 'ghg':
                    return DataNormalizer.normalizeTrendsData(response.json(), tab);

                default:
                    return DataNormalizer.normalizeOTTrendsData(response.json(), tab);
            }
        })
        .catch(err => console.log(err));
    },

    getLocationData: (zipCode) => {
        return fetch(appConfig.locationApiUrl.replace('{zip_code}', zipCode))
        .then(response => response.json())
        .catch(err => console.log(err));
    },

  getChemicalNames: (zipCode, userType) => {
    let url = appConfig.pharosApiUrls.res.replace('{zip_code}', zipCode);
    if (userType === "CBO"){
        url = appConfig.pharosApiUrls.cbo.replace('{zip_code}', zipCode)
    };
    return fetch(url)
      .then(response => { return DataNormalizer.normalizeChemNamesData(response.json()) })
      .catch(err => console.log(err));
  },

  getChemicalsResponse: (chemicalId) => {
    let url = appConfig.pharosApiUrls.details.replace('{id}', chemicalId);
    return fetch(url)
      .then(response => { return DataNormalizer.normalizeChemDetailsResponse(response.json()) })
      .catch(err => console.log(err));
  }
}
export default CommonServoces;

