import React, { useEffect } from 'react'
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBSpinner } from 'mdb-react-ui-kit';
import ModelSlider from './modelSlider';
import appConfig from '../../configs/App.config';
const ForgotPwdModel = ({ isModalOpen, setIsModalOpen, switchModal, toggleOpen, prevModal }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [errors, setErrors] = React.useState({});

    useEffect(() => {
        if(isModalOpen){
            document.querySelectorAll('.modal-bg *').forEach((element) => {
                element.setAttribute('tabIndex', '-1');
            });

            // set attribute to trhe form elements
            if(document.getElementById('PasswordReset'))
                document.getElementById('PasswordReset').setAttribute('tabIndex', '1');

            if(document.getElementById('fpwd-btn'))
                document.getElementById('fpwd-btn').setAttribute('tabIndex', '2');

            if(document.getElementById('login'))
                document.getElementById('login').setAttribute('tabIndex', '3');
            
            setTimeout(() => {
                if(document.getElementById('PasswordReset')){
                    document.getElementById('PasswordReset').focus();
                    var e = new KeyboardEvent('keypress', {'key': 'Tab'});
                    document.getElementById('PasswordReset').dispatchEvent(e);
                }
            });
        }
    });

    const handleForgotPwd = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Validation
        let errors = {};
        if (email === '') {
            errors.email = "Email is required";
        }else if (email !== '') {
            if (!/\S+@\S+\.\S+/.test(email)) {
                errors.email = "Email address is invalid";
            }
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setIsLoading(false);
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "EMAIL": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/forgotpwd`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setErrors(result.errorObject);
                    setSuccess(false);
                } else {
                    // alert('User registered successfully');
                    setSuccess(true);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setErrors(errors);
            });
    }
    return (
        <MDBModal
            backdrop={false}
            show={isModalOpen}
            setShow={setIsModalOpen}
            tabIndex='-1'
            className='shadow-2'>
            <MDBModalDialog centered size='lg'>
                <MDBModalContent className='modal-bg'>
                    <MDBModalHeader>
                        <img className="login-logo"
                            src="/images/login-logo.png" alt="" />
                        <MDBBtn className='btn-close' color='none' onClick={() => toggleOpen('cbo')}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <ModelSlider />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-box">
                                        <div className="inner-form-box">
                                            <i className="fa fa-lock form-icon"></i>
                                            <h3>Forgot Password</h3>
                                            {!success && <><p style={{ color: "#7b7b7b" }}>Enter your email we’ll send you a link to
                                                <br />reset your password!
                                            </p>
                                            {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                                                {Object.values(errors).map((val, index) => {
                                                    return <p style={{
                                                        fontSize: "10px",
                                                        marginBottom: "5px",
                                                        lineHeight: "1rem"
                                                    }} key={index}>* {val}</p>
                                                })}
                                            </div>}
                                            <form onSubmit={handleForgotPwd}>
                                            <div className="form-floating mb-3">
                                                <i className="fa-solid fa-envelope flot-icon"></i>
                                                <input type="email" className="form-control rounded-3" id="PasswordReset"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Please enter registered email" />
                                                <label htmlFor="PasswordReset">Please enter registered email</label>
                                            </div>
                                            </form>
                                            {!isLoading && <><button className="mb-2 btn btn-sm rounded-3 btn-primary form-btn"
                                                onClick={handleForgotPwd}
                                                id="fpwd-btn"
                                                type="submit">Send Verification</button><br />
                                                <a className='text-primary' id='login' onClick={() => {
                                                        switchModal(prevModal);
                                                    }} style={{ cursor: "pointer" }}>Back to Login</a></>}
                                            {isLoading && <MDBBtn disabled className='me-2'>
                                                        <MDBSpinner size='sm' role='status' tag='span' />
                                                        <span className='ml-1'>Verifying...</span>
                                                    </MDBBtn>}
                                            </>}
                                            {success && <div className="mb-3 text-success">
                                                <p style={{
                                                    marginBottom: "5px",
                                                    lineHeight: "2rem"
                                                }}>Password reset link sent to your email <br />
                                                    <a className='text-primary' onClick={() => {
                                                        switchModal(prevModal);
                                                    }} style={{ cursor: "pointer" }}>Back to Login</a>
                                                </p>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default ForgotPwdModel;
