// LoaderComponent.js
import React from 'react';
import './GaugeLoader.css';

const GaugeLoader = () => (
    <div className="loading-container">
    <svg className="circular-loading" viewBox="25 25 50 50">
      <circle className="circular-loading-path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
    </svg>
  </div>
);

export default GaugeLoader;