import React from 'react'

const WelcomeComponent = () => {
    return (
        <>
            <div className="main-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="abt-baner">
                                <img src='../images/abt-ejdh.png'
                                    className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="abt-info">
                            <span className="section-title__tagline">Welcome to EJ DAP</span>
                                <h2 className="abt-title">About the EJ Data Action Portal</h2>
                                <p>The <strong>Deep South Center for Environmental Justice</strong> designed the EJ Data Action Portal to provide accurate zip code-level data on pollution impacting our health, environment, and climate.</p>
                                <p>Our goal in providing this tool is to support community-driven solutions to harmful environmental conditions and severe climate risks. This portal is the first of its kind to be built from the recommendations of environmental justice communities.​</p>
                                <p>The <strong>EJ Data Action Portal</strong> interfaces with the Healthy Building Network’s PHAROS database of chemicals and their scientifically known effects on human health and our environment. ​</p>
                                <a href="/about" className="btn-alink" >Read more <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomeComponent
