import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

const CopyrightsFooter = () => {
  return (
    
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
   

    <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', fontSize: '10px',
    color: '#000' }}>
      © 2024 Copyright &nbsp;  
      <span className='text-reset fw-bold' href='#'>
          EJ Data Action Portal.
      </span>
    </div>
  </MDBFooter>
   
  )
}

export default CopyrightsFooter


