import React, { useEffect, useState } from 'react'
import {
    MDBIcon,
    MDBCollapse,
    MDBRipple,
    MDBAccordion,
    MDBAccordionItem,
    MDBListGroup,
    MDBListGroupItem,
    MDBNavbarBrand,
    MDBBtn,
    MDBNavbarToggler,
    MDBBtnGroup,
    MDBInputGroup,
    MDBInput,
    MDBTooltip
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLeftnavUpdated, selectMiniLeftNav, selectShowLeftnav, setIsLeftnavUpdated, setIsMobileMenu, setMiniLeftNav, setShowLeftnav, setZipCode } from '../../../redux/appSlice';
import { useNavigate } from 'react-router-dom';
import './LeftNav.css';
import { Link, NavLink } from 'react-router-dom';
import appRoutes from '../../../configs/Routes.config';
import { selectToken, selectTokenData, selectUserEmail } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
import { toast } from 'react-toastify';

import Glossary from '../../../modules/app/infoPages/Glossary';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { tourOptions, tourSteps } from '../userTour/UserTourConfig';

const LeftNav = (props) => {
    const dispatch = useDispatch();
    const showLeftNav = useSelector(selectShowLeftnav);
    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const [searchTerm, setSearchTerm] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [savedReports, setSavedReports] = useState([]);
    const userEmail$ = useSelector(selectUserEmail);
    const isLeftnavUpdated$ = useSelector(selectIsLeftnavUpdated);
    const token$ = useSelector(selectToken);
    const userData = useSelector(selectTokenData);
    const navigate = useNavigate();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        // if (selectIsLeftnavUpdated) getSavedReports();
    }, []);
    useEffect(() => {
        if(isLeftnavUpdated$){
            // getSavedReports();
            dispatch(setIsLeftnavUpdated(false))
        }
        
        window.addEventListener("resize", updateDimensions);
        if (width <= 992) {
            dispatch(setShowLeftnav(false));
            dispatch(setIsMobileMenu(true));
        } else {
            dispatch(setShowLeftnav(true));
            dispatch(setIsMobileMenu(false));
        }
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isLeftnavUpdated$]);

    useEffect(() => {
        let openMnu = document.querySelector('.nested-menu.open');
        if (openMnu) {
            openMnu.style.height = isMiniLeftnav ? '66px' : '40px';
            openMnu.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            openMnu.children[0].children[1].style.transform = 'rotate(0deg)';
            openMnu.classList.remove('open');
        }
    }, [isMiniLeftnav]);

    const toggleShow = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
        }
        dispatch(setShowLeftnav());
    }

    const getSavedReports = () => {
        if (userData.USER_TYPE === "RESIDENT") return;

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token$}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/reports/${userData.ID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status >= 400) {
                    toast.error('Faied to fetch saved reports', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    return;
                }
                setSavedReports([...result]);
            })
            .catch(error => console.log('error', error));
    }
    const toggleSubNav = (e) => {
        e.preventDefault();
        let isClosed = false;
        let pelm = e.target;
        while (pelm.tagName !== 'A') {
            pelm = pelm.parentElement;
        }
        if (pelm.tagName === 'A') {
            // pelm.children[1].style.transform = e.target.parentElement.classList.contains('open') ? 'rotate(0deg)' : 'rotate(90deg)';
            pelm = pelm.parentElement;
            isClosed = !pelm.classList.contains('open');
        }

        // if (!isClosed) {
        // close all open sub menu
        let openMnu = document.querySelector('.nested-menu.open');
        if (openMnu) {
            // openMnu = openMnu.parentElement;
            openMnu.style.height = isMiniLeftnav ? '66px' : '40px';
            openMnu.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            openMnu.children[0].children[1].style.transform = 'rotate(0deg)';
            openMnu.classList.remove('open');
        }
        // }

        if (isClosed) {
            pelm.classList.toggle('open');
            pelm.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            pelm.children[0].children[1].style.transform = 'rotate(90deg)';
            let h = pelm.children[1].offsetHeight;
            pelm.style.height = (h + (isMiniLeftnav ? 66 : 40)) + 'px';
        }
    }

    const searchOnchange = (e) => {
        setSearchTerm(e.target.value);
    }

    const doSearch = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
            return;
        }

        dispatch(setZipCode(searchTerm));
        // navigate('/search/'+searchTerm);
        // window.location.replace('/search/'+searchTerm);
        setSearchTerm("");
    }

    const getLabel = (label) => {
        if (isMiniLeftnav && label.length > 10) {
            let parts = label.split(' ');
            label = parts.join('<br />');
        }
        return { __html: label };
    }

    const startUserTour = () => {
        const steps = tourSteps(userData);
        const options = tourOptions(steps);

        const intro = introJs();
        intro.setOptions(options);
        intro.onbeforechange(function (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
        intro.start();
    };

    return (
        <>
            <MDBCollapse tag="nav" className={`d-lg-block bg-white sidebar show ${isMiniLeftnav ? 'mini' : ''}`} style={{
                transform: showLeftNav ? "translateX(0)" : "translateX(-250px)",
            }}>
                <MDBNavbarToggler
                    type='button'
                    className={`bg-light text-primary ${showLeftNav ? 'close-nav' : ''} border-1 border-color-primary`}
                    aria-label='Toggle navigation'
                    onClick={toggleShow}>
                    <MDBIcon icon='times' fas className='text-danger' />
                </MDBNavbarToggler>
                <div className="position-sticky">
                    <MDBNavbarBrand href='#' className='border-bottom'>
                        <img
                            src='/images/logo-ejdh.png'
                            height='30'
                            alt=''
                            loading='lazy'
                        />

                    </MDBNavbarBrand>
                    
                    <div className='menu-container'>
                        <div className='menu-header show-in-mobile border-bottom'>
                            <div className='m-2 my-3 search-box'>
                                <MDBInputGroup className=''>
                                    <input
                                        className={`txtbox ${isMiniLeftnav ? 'hide' : ''}`}
                                        placeholder="Search with zip code"
                                        value={searchTerm}
                                        onChange={searchOnchange}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5);
                                          }}
                                        onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                                doSearch();
                                            }
                                        }}
                                        type='text' />
                                    <button
                                        className={`search-btn ${isMiniLeftnav ? 'mini' : ''}`}
                                        onClick={doSearch}
                                        type='button'>
                                        <MDBIcon fas icon="search" />
                                    </button>
                                </MDBInputGroup>
                            </div>
                        </div>
                        <div className='menu-body scrollbar scrollbar-default'>
                            <MDBListGroup flush="true" className="rounded-0" style={{
                                transition: 'all 0.3s ease-in-out'
                            }}>
                                {
                                    appRoutes.map((route, index) => {

                                        if (userData && (userData.USER_TYPE === "RESIDENT" || route.path !== 'saved-reports')) {
                                            if (route.type === 'divider') {
                                                return (
                                                    <div className='divider' key={`ln-${index}`}></div>
                                                )
                                            } else if (route.type === 'treeMenu') {
                                                return (
                                                   
                                                    <MDBListGroupItem key={`ln-${index}`} tag='li' action className='border-0 border-bottom rounded-0 p-0 nested-menu'
                                                        style={{
                                                            height: (isMiniLeftnav) ? '66px' : '40px'
                                                        }}>
                                                        <MDBRipple rippleTag='a' href='#'
                                                            className={`${isMiniLeftnav ? 'd-block' : 'px-2  py-2 '} w-100 border-bottom border-0 rounded-0`}
                                                            style={{
                                                                height: isMiniLeftnav ? '66px' : '40px',
                                                                transition: 'all 0.3s ease-in-out 0s'
                                                            }}
                                                            onClick={toggleSubNav}>
                                                            <span style={isMiniLeftnav ? {
                                                                maxWidth: '85%',
                                                                // maxHeight: '50px',
                                                                overflow: 'hidden'
                                                            } : {
                                                                maxWidth: '85%',
                                                                maxHeight: '20px',
                                                                // overflow: 'hidden',
                                                            }}>
                                                                {route.faIcon ?
                                                                    <MDBIcon fas icon={route.faIcon} className='me-3 menu-icon' />
                                                                    : <span className='material-symbols-outlined menu-icon' style={
                                                                        (route.matIcon === 'bookmarks' && isMiniLeftnav) ? {
                                                                            fontSize: '24px',
                                                                            marginLeft: '29px',
                                                                            marginBottom: '8px',
                                                                            marginTop: '7px'
                                                                        } : route.matIcon === 'bookmarks' ? {
                                                                            fontSize: '22px',
                                                                            marginBottom: '8px'
                                                                        } : {}}>{route.matIcon}</span>}
                                                                <span className='menuTitle mini-label' dangerouslySetInnerHTML={getLabel(route.label)}></span>
                                                            </span>
                                                            {isMiniLeftnav ?
                                                                <span className="material-symbols-outlined anchor">
                                                                    arrow_drop_down
                                                                </span>
                                                                : <MDBIcon fas icon="angle-down anchor" />}

                                                        </MDBRipple>


                                                
                                                        
                                                        <MDBListGroup flush="true" className="rounded-0 sub-nav">
                                                            {(route.path === 'saved-reports' && savedReports) &&
                                                                savedReports.map((rpt, subindex) => {
                                                                        return (
                                                                            <MDBRipple key={`ln-${index}-${subindex}`} rippleTag='li' className='w-100'>
                                                                                <MDBListGroupItem tag='div' href='#' action className='border-0 border-bottom rounded-0 p-0'>
                                                                                    <NavLink
                                                                                        to={`/${route.path}/${rpt.CODE}`}
                                                                                        className={`${isMiniLeftnav ? '' : 'px-2'} border-0 rounded-0 py-2 d-block w-100`}>
                                                                                        <span className={`material-symbols-outlined ${isMiniLeftnav ? '' : 'ms-3'}`}>bookmark</span>
                                                                                        <span className='menuTitle mini-label' dangerouslySetInnerHTML={getLabel(rpt.NAME)}></span>
                                                                                    </NavLink>
                                                                                </MDBListGroupItem>
                                                                            </MDBRipple>
                                                                        )
                                                                })}
                                                            {(route.path !== 'saved-reports' || savedReports === null) &&
                                                                route.submenus.map((subroute, subindex) => {
                                                                    return (
                                                                        <MDBRipple key={`ln-${index}-${subindex}`} rippleTag='li' className='w-100'>
                                                                            <MDBListGroupItem tag='div' href='#' action className='border-0 border-bottom rounded-0 p-0'>
                                                                                <Link
                                                                                    // to={`/${route.path}/${subroute.path}`}
                                                                                    to={`${subroute.path}`}
                                                                                    target='_blank'
                                                                                    className={`${isMiniLeftnav ? '' : 'px-2'} border-0 rounded-0 py-2 d-block w-100`}>
                                                                                    {subroute.faIcon ?
                                                                                        <MDBIcon fas icon={subroute.faIcon} className={`${isMiniLeftnav ? '' : 'ms-3'} me-3`} />
                                                                                        : <span className={`material-symbols-outlined ${isMiniLeftnav ? '' : 'ms-3'}`}>{subroute.matIcon}</span>}
                                                                                    <span className='menuTitle mini-label' dangerouslySetInnerHTML={getLabel(subroute.label)}></span>
                                                                                </Link>
                                                                            </MDBListGroupItem>
                                                                        </MDBRipple>

                                                                        
                                                                    )
                                                                })
                                                            }
                                                        </MDBListGroup>

                                                    </MDBListGroupItem>

                                                    
                                                 
                                                );
                                            } else {
                                                return (
                                                    <MDBRipple key={index} rippleTag='li'>
                                                        <MDBListGroupItem tag='div' action className='p-0 border-0 border-bottom' >
                                                            <NavLink to={`${route.path}`} className='border-0 rounded-0 px-2 py-2 d-block w-100' >
                                                                {route.faIcon ?
                                                                    <MDBIcon fas icon={route.faIcon} className='me-3 menu-icon' />
                                                                    : <span className='material-symbols-outlined menu-icon'>{route.matIcon}</span>}
                                                                <span className='menuTitle mini-label'>{route.label}</span>
                                                            </NavLink>
                                                        </MDBListGroupItem>
                                                    </MDBRipple>
                                                );
                                            }
                                        }
                                    })
                                }
                            </MDBListGroup>
                        </div>
                        <div className='menu-footer border-top'>

                             <div className='help-box'>
                                <i className="far fa-circle-question" title="User Tour" onClick={startUserTour}></i>
                                <h6>Need Help?</h6>
                                <p>Learn how to use EJ Data Action Portal with our Tutorial</p>
                                <MDBTooltip tag="span" placement="right" title="Glossary">
                                        <NavLink to='/app/glossary' className='rounded-0'>
                                            <span 
                                            className="material-symbols-outlined help-icon" 
                                            style={{ transform: 'translateY(6px)' }}
                                            >
                                               info
                                            </span>
                                            <span className='no-mini-label'>Glossary</span>
                                        </NavLink>
                                        </MDBTooltip>

                                        {/* <MDBTooltip tag='a' placement='right' wrapperProps={{ href: '/app/tutorials' }} title="Tutorials"  className='rounded-0'>
                                            <span className="material-symbols-outlined help-icon" style={{
                                                transform: 'translateY(6px)'
                                            }}>
                                                play_arrow
                                            </span>
                                            <span className='no-mini-label'>Tutorials</span>
                                        </MDBTooltip> */}

                                        { userData.USER_TYPE === "CBO" && 

                                        <MDBTooltip tag="span" placement="right" title="Ask a Question">
                                        <NavLink to='/app/contact' className='rounded-0'>
                                        <span 
                                            className="material-symbols-outlined help-icon" 
                                            style={{ transform: 'translateY(6px)' }}
                                        >
                                            contact_support
                                        </span>
                                        <span className='no-mini-label'>Ask a Question</span>
                                        </NavLink>
                                        </MDBTooltip>
                                        }
                             </div>


                            {/* <MDBListGroup flush="true" className="rounded-0 ">
                                <MDBRipple rippleTag='li' className='col p-0'>
                                    <MDBListGroupItem tag='div' action className='border-0 rounded-0 border-end p-0 text-center'>
                                        <NavLink to='/app/about' className='rounded-0'>
                                            <span className="material-symbols-outlined" style={{
                                                transform: 'translateY(6px)'
                                            }}>
                                                person
                                            </span>
                                            <span className='no-mini-label'>About</span>
                                        </NavLink>
                                    </MDBListGroupItem>
                                </MDBRipple>
                                <MDBRipple rippleTag='li' className='col p-0'>
                                    <MDBListGroupItem tag='div'  action className='border-0 rounded-0 p-0 text-center'>
                                        <NavLink to='/app/glossary' className='rounded-0 '>
                                            <span className="material-symbols-outlined" style={{
                                                transform: 'translateY(6px)'
                                            }}>
                                                info
                                            </span>
                                            <span className='no-mini-label'>Glossary</span>
                                        </NavLink>
                                    </MDBListGroupItem>
                                </MDBRipple>
                            </MDBListGroup> */}

                            
                        </div>
                    </div>

                </div>
            </MDBCollapse>

        </>
    )
}

export default LeftNav
