import React, { useEffect, useRef } from 'react'
import appConfig from '../../configs/App.config';
import { MDBBtn, MDBCheckbox, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBSpinner } from 'mdb-react-ui-kit';
import ModelSlider from './modelSlider';

const ResRegModel = ({ isModalOpen, setIsModalOpen, switchModal, toggleOpen }) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [organization, setOrganization] = React.useState('');
    const [zipcode, setZipcode] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [success, setSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const emailRef = useRef(null);

    useEffect(() => {
        // setTimeout(() => {
            if (isModalOpen) {
                document.querySelectorAll('.modal-bg *').forEach((element) => {
                    element.setAttribute('tabIndex', '-1');
                });
                // set attribute to trhe form elements
                document.getElementById('NewResEmail').setAttribute('tabIndex', '1');
                document.getElementById('NewResPassword').setAttribute('tabIndex', '2');
                document.getElementById('ConfirmPassword').setAttribute('tabIndex', '3');
                // document.getElementById('NewResOrg').setAttribute('tabIndex', '4');
                document.getElementById('NewResZip').setAttribute('tabIndex', '5');
                document.getElementById('NewResAddress').setAttribute('tabIndex', '6');
                document.getElementById('NewRegChecked').setAttribute('tabIndex', '7');
                document.getElementById('btnRegister').setAttribute('tabIndex', '8');

                setTimeout(() => {
                    emailRef.current.focus();
                    var e = new KeyboardEvent('keypress', { 'key': 'Tab' });
                    document.getElementById('NewResEmail').dispatchEvent(e);
                });

            }
        // }, 1000);
    }, [isModalOpen]);

    const registerUser = (e) => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);
        if (password !== confirmPassword) {
            setErrors({ password: 'Password and confirm password should be same' });
            setIsLoading(false);
            return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "FULLNAME": email,
            "EMAIL": email,
            "USER_TYPE": "RESIDENT",
            "USER_NAME": email,
            "PASSWORD": password,
            "LOGIN_STATUS": "LOGGEDOUT",
            "USER_STATUS": "VERIFY",
            "PHONE": null,
            "ZIPCODE": zipcode,
            "COMUNITY_ID": null,
            "CBO_NAME": 'NA',
            "CBO_TYPE": null,
            "CBO_ADDRESS": (!address || address.length <= 0) ? 'NA' : address,
            "BUSINESS_EIN": null,
            "IS_CBO_RIGISTERED": null,
            "AUTHORIZED_REPRESENTATIVE": null,
            "CBO_WEBSITE": null,
            "HAS_EIN_PLAN": null,
            "EIN_TIMEFRAME": null,
            "POSITION": null,
            "BOUNDRY_MAP": null
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/register`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setErrors({ error: result.errMessage });
                    setSuccess(false);
                } else {
                    // alert('User registered successfully');
                    setSuccess(true);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setErrors(error);
                setIsLoading(false);
            });
    }

    // const focusTo = (e, id) => {
    //     // // if (e.key === 'Tab') {
    //     // document.getElementById(id).focus();
    //     // // select the contents
    //     // if (e.target.id !== 'NewRegChecked') {
    //     //     document.getElementById(id).select();
    //     // }
    //     // }
    // }
    return (
        <MDBModal
            backdrop={false}
            show={isModalOpen}
            setShow={setIsModalOpen}
            tabIndex='-1'
            className='shadow-2'>
            <MDBModalDialog centered size='lg'>
                <MDBModalContent className='modal-bg'>
                    <MDBModalHeader>
                        <img className="login-logo"
                            src="/images/login-logo.png" alt="" />
                        <MDBBtn className='btn-close' color='none' onClick={() => toggleOpen('cbo')}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <ModelSlider />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-box">
                                        <form onSubmit={registerUser}>
                                            <div className="inner-form-box2">
                                                <h3 className="form-title2">Join as Resident</h3>
                                                <i className="fa-solid fa-user form-icon"></i>
                                                {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                                                    {Object.values(errors).map((val, index) => {
                                                        return <p style={{
                                                            fontSize: "12px",
                                                            marginBottom: "5px",
                                                            lineHeight: "1rem"
                                                        }} key={index}>* {val}</p>
                                                    })}
                                                </div>}
                                                {!success && <div>
                                                    <div className="form-floating mb-3">
                                                        <input type="email" ref={emailRef} className="form-control rounded-3" id="NewResEmail" tabIndex={1} autoFocus
                                                            placeholder="Your email address" onChange={(e) => { setEmail(e.target.value) }} onKeyDown={(e) => { setEmail(e.target.value) }} />
                                                        <label htmlFor="NewResEmail">Enter your email </label>
                                                        <i className="fa-solid fa-envelope flot-icon"></i>
                                                    </div>

                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control rounded-3" id="NewResPassword" tabIndex={2}
                                                            placeholder="Create Password" onChange={(e) => { setPassword(e.target.value) }} onKeyDown={(e) => { setPassword(e.target.value) }} />
                                                        <label htmlFor="NewResPassword">Create Password</label>
                                                        <i className="fa-solid fa-key flot-icon"></i>
                                                    </div>
                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control rounded-3" id="ConfirmPassword" tabIndex={3}
                                                            placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value) }} onKeyDown={(e) => { setConfirmPassword(e.target.value) }} />
                                                        <label htmlFor="ConfirmPassword">Confirm Password</label>
                                                        <i className="fa-solid fa-lock flot-icon"></i>
                                                    </div>
                                                    {/* <div className="form-floating mb-3">
                                                    <i className="fa-solid fa-sitemap flot-icon"></i>
                                                    <input type="text" className="form-control rounded-3" id="NewResOrg" tabIndex={4}
                                                        placeholder="Organization" onChange={(e) => { setOrganization(e.target.value) }} onKeyDown={(e) => { setOrganization(e.target.value) }}  />
                                                    <label htmlFor="NewResOrg">Organization</label>
                                                </div> */}
                                                    <div className="form-floating mb-3">
                                                        <i className="fa-solid fa-house-lock flot-icon"></i>
                                                        <input type="text" className="form-control rounded-3" id="NewResZip" tabIndex={5}
                                                            placeholder="Enter Zipcode" onChange={(e) => { setZipcode(e.target.value) }} onKeyDown={(e) => { setZipcode(e.target.value) }} />
                                                        <label htmlFor="NewResZip">Enter Zipcode</label>
                                                    </div>
                                                    <div className="form-floating mb-3">
                                                        <i className="fa-solid fa-map-location flot-icon"></i>
                                                        <input type="text" className="form-control rounded-3" id="NewResAddress" tabIndex={6}
                                                            placeholder="Your Address" onChange={(e) => { setAddress(e.target.value) }} onKeyDown={(e) => { setAddress(e.target.value) }} />
                                                        <label htmlFor="NewResAddress">Your Address <small>(Optional)</small></label>
                                                    </div>
                                                    <div className="form-check">
                                                        {/* <input className="form-check-input" type="checkbox" value="" tabIndex={7} id="NewRegChecked" checked onChange={(e) => console.log(e)} />
                                                        <label className="form-check-label" htmlFor="NewRegChecked">
                                                            <small>
                                                                By creating an account, I agree to your <br />
                                                                <a href="#">Terms of use</a> and &nbsp;
                                                                <a href="#">Privacy Policy</a>
                                                            </small>
                                                        </label> */}
                                                        <MDBCheckbox name='flexCheck' value='' id='NewRegChecked' label={
                                                            <small>
                                                            By creating an account, I agree to your <br />
                                                            <a href="#">Terms of use</a> and &nbsp;
                                                            <a href="#">Privacy Policy</a>
                                                        </small>
                                                        } defaultChecked />
                                                    </div>
                                                    <div style={{ overflow: "auto" }}>

                                                        {!isLoading && <button type="submit" id='btnRegister' className='mb-2 btn btn-sm rounded-3 btn-primary form-btn' onClick={registerUser} tabIndex={8}>Register</button>}
                                                        {isLoading && <MDBBtn disabled className='me-2'>
                                                            <MDBSpinner size='sm' role='status' tag='span' />
                                                            <span className='ml-1'>Registering...</span>
                                                        </MDBBtn>}
                                                    </div>

                                                    <p className="form-footer-title">Already have an account? <a type="button"
                                                        id="reslogBtn" onClick={(e) => {
                                                            e.preventDefault();
                                                            switchModal('res')
                                                        }}>Login here</a></p>
                                                </div>}
                                                {success && <div className="mb-3 text-success">
                                                    <p style={{
                                                        marginBottom: "5px",
                                                        lineHeight: "2rem"
                                                    }}>
                                                        Thank you for registering with us. Please check your email for verification link. <br />
                                                        <a type="button" className="text-primary"
                                                            id="reslogBtn" onClick={(e) => {
                                                                e.preventDefault();
                                                                switchModal('res')
                                                            }}>Back to login</a>
                                                    </p>
                                                </div>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default ResRegModel
