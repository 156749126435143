import React, { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Table } from 'antd';
import './Grid.css';

const Grid = ({ gridData, gridHeight, activeTab }) => {
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    useEffect(() => {
        setColDefs(() => {
            if (activeTab === 'TRI' || activeTab === 'GHG') {
                return gridData.headers.map((lbl, i) => {
                    return {
                        title: (i === 0) ? lbl.replace('{tab}', activeTab) : lbl,
                        dataIndex: (i === 0) ? 'feature' : `_${lbl}`,
                        key: (i === 0) ? 'feature' : `_${lbl}`,
                        width: (i === 0) ? '25vw' : 120,
                        fixed: (i === 0) ? 'left' : '',
                        sorter: i === 0 ? (a, b) => {
                            const isLastRowA = a.feature === 'Total';
                            const isLastRowB = b.feature === 'Total';
                            if (isLastRowA || isLastRowB) {
                                return 0; // Disable sorting for last row
                            }
                            return a.feature > b.feature ? 1 : a.feature < b.feature ? -1 : 0;
                        } : null, // Enable sorting only for the first column
                        sortDirections: i === 0 ? ['ascend', 'descend'] : [],
                    };
                });
            } else {
                return gridData.headers.map((lbl, i) => {
                    return {
                        title: (i === 0) ? lbl.replace('{tab}', activeTab) : lbl,
                        dataIndex: (i === 0) ? 'feature' : lbl,
                        key: (i === 0) ? 'feature' : lbl,
                        fixed: (i === 0) ? 'left' : '',
                        sorter: i === 0 ? (a, b) => {
                            const isLastRowA = a.feature === 'Total';
                            const isLastRowB = b.feature === 'Total';
                            if (isLastRowA || isLastRowB) {
                                return 0; // Disable sorting for last row
                            }
                            return a.feature > b.feature ? 1 : a.feature < b.feature ? -1 : 0;
                        } : null, // Enable sorting only for the first column
                        sortDirections: i === 0 ? ['ascend', 'descend'] : [],
                    };
                });
            }
        });

        if (activeTab !== 'TRI' && activeTab !== 'GHG') {
            setRowData(gridData.data);
        } else {
            const footerRow = {};
            const finalData = gridData.data.map((rec) => {
                Object.keys(rec).forEach((key) => {
                    if (key.indexOf('_') === 0) {
                        if (!footerRow[key]) {
                            footerRow[key] = 0;
                        }
                        footerRow[key] = parseFloat(footerRow[key]);
                        footerRow[key] += parseFloat(rec[key]);
                        footerRow[key] = footerRow[key]?.toFixed(2);
                        rec[key] = parseFloat(rec[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    } else {
                        if (key === 'feature') footerRow[key] = 'Total';
                        if (key === 'County') footerRow[key] = '';
                    }
                });

                return rec;
            });

            Object.keys(footerRow).forEach((key) => {
                if (key !== 'feature' && key !== 'County') {
                    footerRow[key] = parseFloat(footerRow[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
            });

            finalData.push(footerRow);
            setRowData(finalData);
        }
    }, [gridData]);

    return (
        <>
            <Table
                className={activeTab.toLowerCase() + '-grid'}
                columns={colDefs}
                dataSource={rowData}
                scroll={{ y: 400 }}
                pagination={false}
            />
        </>
    );
};

export default Grid;
