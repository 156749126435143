import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const CejstMapTxt = () => {
    return (
      <>
        <p>The <strong>Council on Environmental Quality (CEQ)</strong> to develop a tool; the <Link to="https://screeningtool.geoplatform.gov/en/" target='_blank'><u>Climate and Economic Justice Screening Tool(CEJST)</u></Link>. The tool has an interactive map and uses datasets that are indicators of burdens in eight categories: climate change, energy, health, housing, legacy pollution, transportation, water and wastewater, and workforce development. Census tracts that are overburdened and underserved are highlighted as being disadvantaged on the map.</p>
        <p>The tool ranks most of the burdens using percentiles. Percentiles show how much burden each tract experiences when compared to other tracts. A community is highlighted as disadvantaged on the CEJST map if it is in a census tract that is </p>
        <ol>
            <li>At or above the threshold for one or more environmental, climate, or other burdens, and</li>
            <li>At or above the threshold for an associated socioeconomic burden. </li>
        </ol>
        <p>In the map below, we display the CEJST total threshold score. If the score is greater than zero, it will be considered as a disadvantage. </p>
        <p>You can verify the score by clicking on the specific census tract.  </p>
      </>
    )
  
}

export default CejstMapTxt
