import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const TriMapTxt = () => {
    return (
      <>
        <p><strong>Toxic Release Inventory</strong> <Link to="/glossary" target="_blank"><u>(TRI)</u></Link> tracks the management of certain toxic chemicals that may pose a threat to human health and the environment.</p>
        <p>In general, chemicals covered by the TRI Program are those that cause:</p>
        <ul>
            <li>Cancer or other chronic human health effects </li>
            <li>Significant adverse acute human health effects </li>
            <li>Significant adverse environmental effects </li>
        </ul>
        <p>This map displays Toxic Release Inventory (TRI) from large industries in census tracts with minority and poverty population data. The purple boundary <img src='../images/zip-boundry-icon.png' alt='zip code boundry' /> on the map indicates the defined area of interest, which can be a specific zip code or community’s boundary. </p>
        <p>The colored polygons <img src='../images/polygons-icon.png' alt='zip code boundry' /> depict census tracts within the specified boundaries, showing the percentage of the population who are <a href='/glossary' style={{textDecoration: "underline"}} target="_blank">minorities</a> or living below the <a href="/glossary" style={{textDecoration: "underline"}} target="_blank">poverty line</a>. By clicking on the tab <img src='../images/map-select.png' className="img-fluid" alt="" />  in the left-hand corner, users can display GHG emission data releases related to the poverty and minority data.  </p>
        <p><i className="fas fa-play" style={{transform: "rotate(30deg)",  color: "#0767d7"}}></i> Triangles on the map indicate the locations of facilities or industries emitting Toxic chemicals. The size of the blue circles <i className="fas fa-circle" style={{color: "#3aceff"}}></i> around the triangles gives a measure of how much TRI is being emitted at the particular facility. The legend on the right side of the map provides the range TRI releases in metric tons.    </p>
        <p><strong>Note :</strong> Get more information by clicking on the specific triangle or circle about that particular facility. </p>
        <p>The timeline at the bottom provides the emission indicators for the last 10 years. Users can explore and see the trends of TRI releases over these 10 years.</p>
      </>
    )
  
}

export default TriMapTxt
