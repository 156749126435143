const formsConfig = {
    profile: {
        cbo: [
            // {
            //     name: 'FULLNAME',
            //     label: 'CBO Name',
            //     type: 'text',
            //     value: '',
            //     ad_field: 'FULLNAME',
            //     required: false,
            //     disabled: true,
            //     // order: 1
            // },
            // {
            //     name: 'COMUNITY_ID',
            //     label: 'Comunity ID',
            //     type: 'text',
            //     value: '',
            //     ad_field: 'COMUNITY_ID',
            //     required: true,
            //     disabled: false,
            //     // order: 5
            // },
            {
                name: 'BUSINESS_EIN',
                label: 'Business EIN',
                type: 'text',
                value: '',
                ad_field: 'BUSINESS_EIN',
                required: true,
                disabled: false,
                // order: 32
            },
            {
                name: 'AUTHORIZED_REPRESENTATIVE',
                label: 'Organization Authorized Representative Name',
                type: 'text',
                value: '',
                ad_field: 'AUTHORIZED_REPRESENTATIVE',
                required: true,
                disabled: false,
                // order: 35
            },
            {
                name: 'PHONE',
                label: 'Phone Number',
                type: 'text',
                value: '',
                ad_field: 'PHONE',
                required: true,
                disabled: false,
                // order: 7
            },
            {
                name: 'EMAIL',
                label: 'Email Address',
                type: 'email',
                value: '',
                ad_field: 'EMAIL',
                required: true,
                disabled: false,
                // order: 4
            },
            {
                name: 'CBO_TYPE',
                label: 'Type of Organization',
                type: 'text',
                value: '',
                ad_field: 'CBO_TYPE',
                required: true,
                disabled: false,
                // order: 30
            },
            {
                name: 'CBO_WEBSITE',
                label: 'CBO Website (if available)',
                type: 'url',
                value: '',
                ad_field: 'CBO_WEBSITE',
                required: false,
                disabled: false,
                
                // order: 31
            },
            {
                name: 'IS_CBO_RIGISTERED',
                label: 'Is CBO Registered with IRS',
                type: 'oprion',
                option: [
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                ],
                value: 'Yes',
                ad_field: 'IS_CBO_RIGISTERED',
                required: true,
                disabled: false,
                // order: 2
            },
            
            {
                name: 'CBO_ADDRESS',
                label: 'CBO Mailing Address',
                type: 'textarea',
                value: '',
                ad_field: 'CBO_ADDRESS',
                required: true,
                disabled: false,
                // order: 10
            },
            // {
            //     name: 'ZIPCODE',
            //     label: 'ZIP Code',
            //     type: 'text',
            //     value: '',
            //     ad_field: 'ZIPCODE',
            //     required: true,
            //     disabled: false,
            //     // order: 10
            // },
            
            // {
            //     name: 'CBO_NAME',
            //     label: 'Community Based Organization Name',
            //     type: 'text',
            //     value: '',
            //     ad_field: 'CBO_NAME',
            //     required: true,
            //     disabled: false,
            //     // order: 8
            // },
            
            
            
            {
                name: 'HAS_EIN_PLAN',
                label: 'Does, the organization has plans to obtain EIN',
                type: 'oprion',
                option: [
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                ],
                value: 'Yes',
                ad_field: 'HAS_EIN_PLAN',
                required: true,
                disabled: false,
                // order: 33
            },
            {
                name: 'EIN_TIMEFRAME',
                label: 'What is the time frame to get the EIN',
                type: 'text',
                value: '',
                ad_field: 'EIN_TIMEFRAME',
                required: true,
                disabled: false,
                // order: 34
            },
            
            {
                name: 'POSITION',
                label: 'Position',
                type: 'text',
                value: '',
                ad_field: 'POSITION',
                required: true,
                disabled: false,
                // order: 36
            },
            
            // {
            //     name: 'BOUNDRY_MAP',
            //     label: 'Upload CBO boundary map in KMZ/KML format',
            //     type: 'text',
            //     value: '',
            //     ad_field: 'BOUNDRY_MAP',
            //     required: true,
            //     disabled: false,
            //     // order: 38
            // }
        ],

        res: [
            {
                name: 'FULLNAME',
                label: 'Full Name',
                type: 'text',
                value: '',
                ad_field: 'FULLNAME',
                required: false,
                disabled: false,
                // order: 1
            },
            {
                name: 'ZIPCODE',
                label: 'Zip Code',
                type: 'text',
                value: '',
                ad_field: 'ZIPCODE',
                required: true,
                disabled: false,
                // order: 5
            },
            {
                name: 'PHONE',
                label: 'Phone Number',
                type: 'text',
                value: '',
                ad_field: 'PHONE',
                required: true,
                disabled: false,
                // order: 7
            },
            {
                name: 'EMAIL',
                label: 'Email Address',
                type: 'email',
                value: '',
                ad_field: 'EMAIL',
                required: true,
                disabled: true,
                // order: 4
            }
        ]
    }
}

export default formsConfig;