import React from 'react'


const PieTxt = () => {
    return (
    <div>
        <h5>Facilities percentage share of total Greenhouse Gas Emissions</h5>
        <p style={{padding: "0px 18% 0px 0px"}}>The pie chart provides a measure of the contributions to the emission from individual facilities within the designated area, offering a clear visualization of which facility is the primary emitter of greenhouse gases. 
        </p>
    </div>
    )
  
}

export default PieTxt