
import HomeHeadder from "../../home/headder";
import HomeHeadderTwo from "../../home/headderTwo";
import WelcomeComponent from "../../home/welcome";
import SVG from "../../home/svg";
import '../../home/homePage.css'
import ExploreComponent from "../../home/explore";
import WhatsTheFunctionComponent from "../../home/whatsthefunction";
import QnAComponent from "../../home/qna";
import SupportComponent from "../../home/support";
import { Footer } from "antd/es/layout/layout";
import FooterComponent from "../../home/footer";
import AboutSection from "./aboutSection";

function AboutEjdap() {   
    return (
        <>
            {/* <script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js"
                integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
                crossorigin="anonymous"></script>
            <script src="/js/bootstrap.bundle.min.js"></script> */}


            <SVG />
            <HomeHeadderTwo />
            <AboutSection />
            
            <FooterComponent />
        </>
    );
}

export default AboutEjdap;