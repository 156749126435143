import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import azureGraph from '../../../services/azureGraph';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSpinner } from 'mdb-react-ui-kit';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, selectTokenData, selectUserMode, updateUser } from '../../../redux/authSlice';
import './Profile.css';
import axios from 'axios';
import FormMaker from '../../../services/formMaker';
import formsConfig from '../../../configs/forms.config';
import appConfig from '../../../configs/App.config';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '../../common/progress/Circular';

const Profile = (props) => {
    // const { id } = useParams();
    const dispatch = useDispatch();
    const userData = useSelector(selectTokenData);
    const token = useSelector(selectToken);
    const userMode$ = useSelector(selectUserMode);
    const location = useLocation();
    const [objid, setObjid] = useState(null);
    const [urlParts, setUrlParts] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState();
    const [formOptions, setFormOptions] = useState({
        mode: 'view',
        onChangeHandler: (e) => {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    [e.target.dataset.ad_field]: e.target.value
                    // [e.target.dataset.ad_field]: e.target.value
                }
            });
        }
    });

    useEffect(() => {
        getProfile();
    }, [])

    useEffect(() => {
        const urlParts = location.pathname.split('/');
        urlParts[urlParts.length - 1] = urlParts[urlParts.length - 1].split('#')[0];
        setUrlParts(urlParts);

    }, [location])

    useEffect(() => {
        if (userData) {
            // set for data basedon ad_field for userData and form config
            const frmData = {};
            formsConfig.profile[userData.USER_TYPE==="CBO" ? 'cbo' : 'res'].forEach(field => {
                frmData[field.ad_field] = userData[field.ad_field];
            });
            setFormData(frmData);
        }
    }, [userData])

    const editProfile = () => {
        // setEditState(!editState);
        setFormOptions((prevState) => {
            return {
                ...prevState,
                mode: prevState.mode === 'view' ? 'edit' : 'view'
            }
        })
    }

    const getProfile = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${appConfig.apiBaseUrl}/auth/profile/read/${userData.OBJID || urlParts[urlParts.length-1]}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setIsLoading(false);
                    toast.error("Error getting profile data");
                } else {
                    result.COMUNITY_ID = result.COMUNITY_ID;
                    dispatch(updateUser(result));
                }
            })
            .catch(error => console.log('error', error));
    }

    const saveProfile = () => {
        if(userMode$ === 'R'){
            toast.error("You are not authorized to update profile data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            
            });
            return false;
        }
        setIsLoading(true);
        const newFormData = {
            "USER_TYPE": userData.USER_TYPE,
            "FULLNAME": userData.FULLNAME,
            "EMAIL": userData.EMAIL,
            "PHONE": userData.PHONE,
            "ZIPCODE": userData.ZIPCODE,
            "COMUNITY_ID": userData.COMUNITY_ID,
            "CBO_NAME": userData.CBO_NAME,
            "CBO_TYPE": userData.CBO_TYPE,
            "CBO_ADDRESS": userData.CBO_ADDRESS,
            "BUSINESS_EIN": userData.BUSINESS_EIN,
            "IS_CBO_RIGISTERED": userData.IS_CBO_RIGISTERED,
            "AUTHORIZED_REPRESENTATIVE": userData.AUTHORIZED_REPRESENTATIVE,
            "CBO_WEBSITE": userData.CBO_WEBSITE,
            "HAS_EIN_PLAN": userData.HAS_EIN_PLAN,
            "EIN_TIMEFRAME": userData.EIN_TIMEFRAME,
            "POSITION": userData.POSITION,
            "BOUNDRY_MAP": userData.BOUNDRY_MAP
        };
        
        for (const key in formData) {
            newFormData[key] = formData[key]==='Yes'? true : formData[key]==='No'? false : formData[key];
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var raw = JSON.stringify(newFormData);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/profile/update/${userData.OBJID || objid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setIsLoading(false);
                    toast.error("Error updating profile data");
                } else {
                    result.COMUNITY_ID = result.COMUNITY_ID;
                    dispatch(updateUser(result));
                    // setEditState(!editState);
                    setFormOptions((prevState) => {
                        return {
                            ...prevState,
                            mode: prevState.mode === 'view' ? 'edit' : 'view'
                        }
                    });
                    toast.success("Profile updated successfully");
                    setIsLoading(false);
                }
            })
            .catch(error => console.log('error', error));
    }



    return (
        <MDBContainer fluid>
            <ToastContainer />
            <MDBRow>
            <MDBCol size='4'>
            <div className="card mb-4">
          <div className="card-body text-center pt-5 pb-5">
            <img src="/./favicon.ico" alt="avatar"
              className="img-fluid mb-3" style={{width: "250px"}}/>
            <h5 className="my-3 mb-4">{userData.FULLNAME}</h5>
            <div className="d-flex justify-content-center mb-2">
            {formOptions.mode === 'view' && <MDBBtn color='primary' size='lg'
                     onClick={editProfile}>
                    <MDBIcon fas icon="pencil-alt" className='pe-2' /> Edit Profile</MDBBtn>}
            </div>
          </div>
        </div>

            </MDBCol>
            <MDBCol size='8'>
                <div className='card'>
                {/* {editState && <> */}
                <FormMaker
                    form={formsConfig.profile[userData.USER_TYPE === "CBO" ? 'cbo' : 'res']}
                    formData={formData ? formData : {}}
                    rawData={userData}
                    options={formOptions}
                />
                {formOptions.mode === 'edit' &&
                    <div className='p-2 text-center mb-4'>
                        <MDBBtn color='primary' size='lg me-2' disabled={isLoading} onClick={saveProfile}>
                            {isLoading ? 
                            <MDBSpinner className='me-2' size='sm' role='status' tag='span' /> 
                            : <MDBIcon fas icon="save" className='pe-2' />}
                            Update Profile
                        </MDBBtn>

                        <MDBBtn color='danger' size='lg ms-2' disabled={isLoading} onClick={editProfile}>
                            <MDBIcon fas icon="times" className='pe-2' /> Cancel
                        </MDBBtn>
                    </div>

                }
                </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default Profile
