import HomeHeadder from "./headder";
import WelcomeComponent from "./welcome";
import SVG from "./svg";
import './homePage.css'
import ExploreComponent from "./explore";
import WhatsTheFunctionComponent from "./whatsthefunction";
import QnAComponent from "./qna";
import SupportComponent from "./support";
import { Footer } from "antd/es/layout/layout";
import FooterComponent from "./footer";

function HomePage() {   
    return (
        <>
            {/* <script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js"
                integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
                crossorigin="anonymous"></script>
            <script src="/js/bootstrap.bundle.min.js"></script> */}


            <SVG />
            <HomeHeadder />
            <WelcomeComponent />
            <ExploreComponent />
            <WhatsTheFunctionComponent />
            <QnAComponent />
            {/* <SupportComponent /> */}
            <FooterComponent />
        </>
    );
}

export default HomePage;