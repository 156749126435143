import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

const WildComponent = (props) => {
    // const { id } = useParams();
    const [urlParts, setUrlParts] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const urlParts = location.pathname.split('/');
        setUrlParts(urlParts);
    }, [location])
    return (
        <div style={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 70px)',
            flexDirection: 'column',
            verticalAlign: 'middle'
        }}>
            <h1 style={{
                textTransform: 'uppercase',
            }}>{urlParts[1]}</h1>
            <h2>Page ID: {urlParts[2]}</h2>
            {props.children}
        </div>
    )
}

export default WildComponent
