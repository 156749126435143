import React from 'react'

const ModelSlider = () => {
    return (
        <>
            <div className="login-content">
                <h3>EJ Data Action Portal</h3>
                <p>The EJ Data Action Portal hosts a wide range of data related to environmental justice, including demographic data, pollution levels, health outcomes, socioeconomic indicators, and more.
                </p>

                <div id="carouselExampleCaptions" className="carousel slide pt-10">
                   
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src="/images/login-slide1.png"
                                className="w-65" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                {/* <h5>Explore CBO</h5> */}
                                <p>This comprehensive dataset allows CBO's to examine the intersection of environmental factors and social disparities.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModelSlider
