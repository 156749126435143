import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectTokenData, selectUserMode } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config'
import Iframe from 'react-iframe'
import { MDBContainer, MDBBtn, MDBInput, MDBSpinner, MDBIcon, MDBInputGroup, MDBInputGroupText } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import './ChangePwd.css'
const ChangePwd = () => {
    const userData = useSelector(selectTokenData);
    const userMode$ = useSelector(selectUserMode);
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [validations, setValidations] = useState({
        len: false,
        upper: false,
        lower: false,
        num: false,
        special: false,
        matchPwd: false
    });

    //show/hide password
    const [passwordType, setPasswordType] = useState('password');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
        
    const togglePasswordVisibility = () => {
            setPasswordType(passwordType === 'password' ? 'text' : 'password');
            setIsPasswordVisible(!isPasswordVisible);
    }
        

    const handlePasswordChange = (password, flag) => {
        if (flag === 'confirm') {
            setConfirmPassword(password);
        } else {
            let len = password.length >= 6;
            let upper = password.match(/[A-Z]/);
            let lower = password.match(/[a-z]/);
            let num = password.match(/[0-9]/);
            let special = password.match(/[!@#$%^&*(),.?":{}|<>]/);
            let match = (flag === "confirm") ? false : password === confirmPassword;
            setValidations({
                len: len,
                upper: upper,
                lower: lower,
                num: num,
                special: special,
                matchPwd: match
            });

            setNewPassword(password);
        }
    }

    const changePassword = () => {
        if(userMode$ === 'R'){
            // setErrors({ message: "You are not authorized to change password" });
            toast.error("You are not authorized to change password");
            return false;
        }
        setIsLoading(true);
        if (newPassword !== confirmPassword) {
            setErrors({ password: "New Password and Confirm Password should be same" });
            setIsLoading(false);
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "PASSWORD": newPassword,
            "CONFIRM_PASSWORD": confirmPassword,
            "OBJID": userData.OBJID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/resetpwd`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setErrors({ message: result.errMessage || "Failed to change password" });
                    setIsLoading(false)
                } else {
                    setIsLoading(false);
                    setErrors({});
                    sessionStorage.removeItem('ejdh_app_state');
                    window.location.replace('/logout');
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
        <ToastContainer />
        <MDBContainer className='w-80'>
            <div className='row text-center'>
                <div className='col-6 mt-5 password-form'>
                   <MDBIcon fas icon="fingerprint" className='pwd-icon ' />
                    <h4 className='mb-4'>Change Password</h4>
                    {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                        {Object.values(errors).map((val, index) => {
                            return <p style={{
                                fontSize: "15px",
                                marginBottom: "5px",
                                lineHeight: "1rem"
                            }} key={index}>{val}</p>
                        })}
                    </div>}
                    <div className="position-relative">
                    <MDBInput
                        className='mb-3 bg-white'
                        label='New Password'
                        onChange={(e) => { handlePasswordChange(e.target.value, 'new') }}
                        onKeyUp={(e) => { handlePasswordChange(e.target.value, 'new') }}
                        id='newPassword'
                        type={passwordType} />
                        <MDBIcon far icon={isPasswordVisible ? 'eye-slash' : 'eye'} id='togglePassword' onClick={togglePasswordVisibility} />
                    </div>
                    <MDBInput
                        className='mb-3 bg-white'
                        label='Confirm Password'
                        onChange={(e) => { handlePasswordChange(e.target.value, 'confirm') }}
                        onKeyUp={(e) => { handlePasswordChange(e.target.value, 'confirm') }}
                        id='confirmPassword'
                        type='password' />
                    <div className=''>
                        <MDBBtn className='me-1' disabled={isLoading} onClick={changePassword}>
                            {isLoading && <MDBSpinner className='me-2' size='sm' role='status' tag='span' />}
                            Change Password
                        </MDBBtn>
                    </div>
                </div>
                <div className='col-6 mt-5 pt-3 form-valid ' style={{ textAlign: "left" }}>
                    <ul>
                        <li className={validations.len ? 'text-success' : 'text-danger'}>Must be at least 6 characters long</li>
                        <li className={validations.upper ? 'text-success' : 'text-danger'}>Must contain at least 1 uppercase letter</li>
                        <li className={validations.lower ? 'text-success' : 'text-danger'}>Must contain at least 1 lowercase letter</li>
                        <li className={validations.num ? 'text-success' : 'text-danger'}>Must contain at least 1 number</li>
                        <li className={validations.special ? 'text-success' : 'text-danger'}>Must contain at least 1 special character</li>
                        <li className={(newPassword.length && confirmPassword.length && newPassword === confirmPassword) ? 'text-success' : 'text-danger'}>
                            New Password and Confirm Password should be same
                        </li>
                    </ul>
                </div>
            </div>
            </MDBContainer>
        </>
    )
}

export default ChangePwd
