import { MDBCol, MDBInput, MDBRadio, MDBRow, MDBTextArea } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'

const FormMaker = ({ form, formData, rawData, options }) => {
    const viewStyle = {
        backgroundColor: 'transparent',
        border: '0 !important',
        boxShadow: 'none !important',
    }

    return (
        <div className='form-container no-shadow'>
            <MDBRow>

            <MDBCol size='12' className='mb-3 p-0' >
                <h4>Profile Info</h4>
            </MDBCol>
                {form.map((field, ind) => {
                    return (
                        <MDBCol size='12' className='field-row' >
                            <span className='fieldLabel'>{field.label} </span> <span className="pe-2" style={{float: "left"}}>:</span>
                            {/* <input type={field.type} value={formData[field.ad_field]} /> */}

                            {field.type === 'text' && <>
                                {options.mode === "view" ?
                                    <div key={field.ad_field}>
                                        {formData[field.ad_field] || '---'}
                                    </div> :
                                     <MDBInput
                                        className={options.mode === "view" ? ' border border-0' : ''}
                                        label={rawData[field.ad_field]}
                                        onChange={options.onChangeHandler}
                                        onKeyUp={options.onChangeHandler}
                                        // value={formData[field.ad_field]}
                                        id={field.name}
                                        disabled={field.disabled}
                                        data-ad_field={field.ad_field}
                                        type={field.type} />
                                }</>}
                            {field.type === 'textarea' && <>
                                {options.mode === "view" ?
                                    <div key={field.ad_field}>
                                        {formData[field.ad_field] || '--'}
                                    </div> :
                                    <MDBTextArea
                                        readOnly={options.mode === "view"}
                                        style={options.mode === "view" ? viewStyle : {}}
                                        label={rawData[field.ad_field]===null? "":rawData[field.ad_field]}
                                        onChange={(e) => { options.onChangeHandler(e) }}
                                        // value={rawData[field.ad_field]===null? "":rawData[field.ad_field]}
                                        data-ad_field={field.ad_field}
                                        disabled={field.disabled}
                                        id={field.name} />
                                        }
                            </>}

                            {(field.type === 'email' || field.type === 'url') && <>
                                {options.mode === "view" ?
                                    <div key={field.ad_field}>
                                        {field.type === 'url' ? <a href={formData[field.ad_field]}>{formData[field.ad_field]}</a> : formData[field.ad_field]}
                                        {!field.type === 'url' && (formData[field.ad_field] || '--')}
                                    </div> :
                                    <MDBInput
                                        readOnly={options.mode === "view"}
                                        style={options.mode === "view" ? viewStyle : {}}
                                        label={rawData[field.ad_field]}
                                        onChange={(e) => { options.onChangeHandler(e) }}
                                        // value={formData[field.ad_field]}
                                        id={field.name}
                                        disabled={field.disabled}
                                        data-ad_field={field.ad_field}
                                        type={field.type} />
                                        }
                            </>}
                            {field.type === 'oprion' && <>
                                {options.mode === "view" ?
                                    <div key={field.ad_field}>
                                        {formData[field.ad_field]===null? '--' : (formData[field.ad_field]? "Yes":"No")}
                                    </div> :
                                    <>
                                        {field.option.map((option, ind) => {
                                            return (
                                                <MDBRadio
                                                    onChange={(e) => { options.onChangeHandler(e) }}
                                                    key={`${field.name}_${ind}_${option.label}`}
                                                    name={field.name}
                                                    checked={option.value === (formData[field.ad_field]? "Yes":"No") || option.value === formData[field.ad_field]}
                                                    data-ad_field={field.ad_field}
                                                    id={`${field.ad_field}_${ind}_${option.label}`}
                                                    value={option.value}
                                                    selected={option.value === formData[field.ad_field]}
                                                    label={option.label} inline />
                                            )
                                        })} 
                                        </>}
                            </>}

                        </MDBCol>
                    )

                })}
            </MDBRow>
        </div>
    )
}

export default FormMaker