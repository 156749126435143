import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'
import ModelSlider from './modelSlider';
import appConfig from '../../configs/App.config';

const ResLoginModel = ({ isModalOpen, setIsModalOpen, switchModal, toggleOpen, setPrevModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (isModalOpen) {
        document.querySelectorAll('.modal-bg *').forEach((element) => {
            element.setAttribute('tabIndex', '-1');
        });
            // set attribute to trhe form elements
            document.getElementById('ResUser').setAttribute('tabIndex', '1');
            document.getElementById('ResPassword').setAttribute('tabIndex', '2');
            document.getElementById('ResLoginBtn').setAttribute('tabIndex', '3');
            document.getElementById('passwordBtn').setAttribute('tabIndex', '4');
            document.getElementById('regBtn2').setAttribute('tabIndex', '5');
            

            setTimeout(() => {
                document.getElementById('ResUser').focus();
                // fire key press event to move the focus to the first element
                var e = new KeyboardEvent('keypress', {'key': 'Tab'});
                document.getElementById('ResUser').dispatchEvent(e);
            }, 100);
        }
    }, [isModalOpen]);

    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        // Validations
        let errors = {};
        if (email === '') {
            errors.email = "Email is required";
        }
        else if (email !== '') {
            if (!/\S+@\S+\.\S+/.test(email)) {
                errors.email = "Email address is invalid";
            }
        }
        if (password === '') {
            errors.password = "Password is required";
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setIsLoading(false);
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "USER_NAME": email,
            "PASSWORD": password,
            "USER_TYPE": "RESIDENT"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/login`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setErrors({ "message": result.errMessage });
                    setIsLoading(false)
                } else {
                    // redirect to /auth/verify#token=token
                    if (result.token) {
                        window.location.replace(`/auth/verify#token=${result.token}`);
                    } else {
                        setErrors({ "message": "Failed to login, please try again." })
                    }
                    setIsLoading(false)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log('error', error)
                setIsLoading(false);
            });
    }

    return (
        <MDBModal
            backdrop={false}
            show={isModalOpen}
            setShow={setIsModalOpen}
            tabIndex='-1'
            className='shadow-2'>
            <MDBModalDialog centered size='lg'>
                <MDBModalContent className='modal-bg'>
                    <MDBModalHeader>
                        <img className="login-logo"
                            src="/images/login-logo.png" alt="" />
                        <MDBBtn className='btn-close' color='none' onClick={() => toggleOpen('cbo')}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <ModelSlider />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-box">
                                        <form onSubmit={handleLogin}>
                                            <div className="inner-form-box">
                                                <h3 className="form-title2">Resident Login</h3>
                                                <i className="fa-solid fa-user form-icon"></i>
                                                <p style={{ color: "#7b7b7b" }}><strong>Login to access your account </strong></p>
                                                {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                                                    {Object.values(errors).map((val, index) => {
                                                        return <p style={{
                                                            fontSize: "15px",
                                                            marginBottom: "5px",
                                                            lineHeight: "1rem"
                                                        }} key={index}>{val}</p>
                                                    })}
                                                </div>}
                                                <div className="form-floating mb-3">
                                                    <i className="fa-solid fa-user flot-icon"></i>
                                                    <input type="email" className="form-control rounded-3" id="ResUser"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        tabIndex="-11"
                                                        placeholder="User email" />
                                                    <label htmlFor="ResUser">User email</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <i className="fa-solid fa-key flot-icon"></i>
                                                    <input type="password" className="form-control rounded-3" id="ResPassword"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        tabIndex="-12"
                                                        placeholder="Password" />
                                                    <label htmlFor="ResPassword">Password</label>
                                                </div>
                                                {/* <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="ResLoginChecked" checked />
                                                    <label className="form-check-label" htmlFor="ResLoginChecked" >
                                                        Remember me
                                                    </label>
                                            </div> */}
                                                <button className="mb-2 btn btn-sm rounded-3 btn-primary form-btn"
                                                    id="ResLoginBtn"
                                                    disabled={isLoading}
                                                    onClick={handleLogin}
                                                    tabIndex="-13"
                                                    type="submit">
                                                    {isLoading && <MDBSpinner className='me-2' size='sm' role='status' tag='span' />}
                                                    Login</button> <br />
                                                <a type="button" id="passwordBtn" tabIndex="-14" 
                                                
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setPrevModal('res');
                                                    switchModal('fpwd');
                                                }}>Forgot Password?</a>
                                                <p className="form-footer-title">Don't have an account? <a type="button" id="regBtn2"
                                                tabIndex="-15"
                                                
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    switchModal('resreg')
                                                }}>Signup here</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default ResLoginModel
