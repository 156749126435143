import React, { useEffect, useState } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
    MDBSpinner,
} from 'mdb-react-ui-kit';
import './headder.css';
import CBOLoginModel from './cboLoginModel';
import ForgotPwdModel from './forgotPwdModel';
import ResLoginModel from './resLoginModel';
import ResRegModel from './resRegModel';
import appConfig from '../../configs/App.config';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectTokenData } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';

const HomeHeadder = () => {
    const [openBasic, setOpenBasic] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [prevModal, setPrevModal] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user$ = useSelector(selectTokenData);

    const [isScrolled, setIsScrolled] = useState(false);

    const toggleNavbar = () => {
        setOpenBasic(!openBasic);
    };

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsScrolled(scrollTop > 0);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    // useEffect(() => {
    //     if (user$) {
    //         if(user$.USER_TYPE === "RESIDENT"){
    //             dispatch(login({
    //                 token: null,
    //                 tokenData: null,
    //                 mode: "X"
    //             }));
    //         }
    //     }
    // }, []);

    useEffect(() => {
        if (user$) {
            // if(user$.USER_TYPE === "RESIDENT"){
                navigate('/app/dashboard');
            // }
        }
    }, [user$]);

    const toggleOpen = (mod) => {
        setModalType(mod);
        setIsModalOpen(!isModalOpen);
    };

    const loginAsResident = (e) => {
        e.preventDefault();
        const resident = {
            "ID":null,
            "OBJID":null,
            "LOGIN_ID":null,
            "USER_TYPE":"RESIDENT",
            "FULLNAME":"Guest User",
            "EMAIL":null,
            "PHONE":null,
            "ZIPCODE":"70127",
            "COMUNITY_ID":null,
        };
        // Generate randome string
        const salt = "EJDH-RESIDENT-SECRET-KEY-2024-03-09-12:00:00-PM"
        const HMACSHA256 = (stringToSign, secret) => "not_implemented"
        const header = {
            "alg": "HS256",
            "typ": "JWT"
        }
        const encodedHeaders = btoa(JSON.stringify(header)); 
        const encodedPlayload = btoa(JSON.stringify(resident));
        const signature = HMACSHA256(`${encodedHeaders}.${encodedPlayload}`, salt)
        const encodedSignature = btoa(signature)
        const token = `${encodedHeaders}.${encodedPlayload}.${encodedSignature}`
        dispatch(login({
            token: token,
            tokenData: resident,
            mode: "X"
        }));
    };

    return (
        <>
            <div className="header-wraper">
                <div className='header h-top fixed-header'>
                    <MDBNavbar expand='lg'>
                        <MDBContainer >
                            <MDBNavbarBrand href='/home' className='public-brand'>
                                <img src="/images/logo-ejdh.png"
                                    alt="Environmental Justice Data Action Portal" />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={toggleNavbar}
                                >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>
                            <MDBCollapse navbar show={openBasic}>
                                <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                                   <MDBNavbarItem>
                                        <MDBNavbarLink aria-current='page' tag='a' href='#' onClick={loginAsResident}>
                                            Explore Data
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                Data Resources
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                <MDBDropdownItem > <a href="https://www.census.gov/" target='_blank' className="dropdown-item"><img src='../images/resource-icon1.png' alt='' /> Demography</a></MDBDropdownItem>


                                                <MDBDropdownItem ><a href="https://www.epa.gov/toxics-release-inventory-tri-program/tri-data-and-tools"  target='_blank' className="dropdown-item"><img src='../images/resource-icon2.png' alt='' /> Toxic Release Inventory</a></MDBDropdownItem>
                                                <MDBDropdownItem ><a href="https://screeningtool.geoplatform.gov/en/#3/33.47/-97.5"  target='_blank' className="dropdown-item"><img src='../images/resource-icon5.png' alt='' /> Climate and Economic Justice Screening Tool</a></MDBDropdownItem>
                                                <MDBDropdownItem ><a href="https://www.epa.gov/ghgreporting" target='_blank' className="dropdown-item"><img src='../images/resource-icon2.png' alt='' /> Greenhouse Gases Release</a></MDBDropdownItem>
                                                <MDBDropdownItem ><a href="https://www.atsdr.cdc.gov/placeandhealth/eji/index.html" target='_blank' className="dropdown-item"><img src='../images/resource-icon3.png' alt='' /> Environmental Justice Index</a></MDBDropdownItem>
                                                <MDBDropdownItem ><a href="https://www.atsdr.cdc.gov/placeandhealth/svi/index.html" target='_blank' className="dropdown-item"><img src='../images/resource-icon3.png' alt='' /> Social Vulnerability Index</a></MDBDropdownItem>
                                                <MDBDropdownItem ><a href="https://pharos.habitablefuture.org/" target='_blank' className="dropdown-item"><img src='../images/resource-icon4.png' alt='' /> Health Impact</a></MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                              Help
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                <MDBDropdownItem href="/about" link>About EJ DAP</MDBDropdownItem>
                                                <MDBDropdownItem><a href="/faqs" className="dropdown-item">FAQ's</a></MDBDropdownItem>
                                                <MDBDropdownItem><a href="/glossary" className="dropdown-item">Glossary</a></MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBNavbarItem>

                                    
                                </MDBNavbarNav>

                            {/* <div className='d-flex  w-auto'>
                                <MDBBtn color='primary'>Search</MDBBtn> 
                                <button type="button" id="btn_cbo" className="d-flex btn btn-link btn-cbo" onClick={() => window.location.replace(`${appConfig.apiBaseUrl}/admin`)}>ADMIN</button>
                                <button type="button" id="btn_cbo" className="d-flex btn btn-link btn-cbo btn-res" onClick={() => toggleOpen('cbo')}>CBO <i
                                    className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                <button type="button" id="btn_res" className="d-flex btn btn-link btn-res" onClick={() => toggleOpen('res')}>Resident</button> 
                                <button type="button" id="btn_res" className="d-flex btn btn-link btn-res" onClick={loginAsResident}>Resident</button>

                            </div> */}
                            </MDBCollapse>
                        </MDBContainer>
                    </MDBNavbar>
                </div>
                <MDBContainer>
                    <div className='grid'>
                        <div className='g-col-12'>
                            <div className="banner">
                                  <img src="/images/ejdap-banner-logo.png"
                                    alt="Environmental Justice Data Action Portal" />
                                <p className="lead mb-4">
                                Every Zip code reveals a story about <br />
                                environmental burden. Find yours.
                                </p>

                                <MDBBtn className='me-4 btn-blue' onClick={loginAsResident}>
                                  Explore <br/>Data
                                </MDBBtn>
                                <MDBBtn className='me-1 p-5 btn-red' onClick={(e) => {
                                        e.preventDefault();
                                        toggleOpen('cbo');
                                    }}>
                                    Member <br/>Login
                                </MDBBtn>
                               
                            </div>
                        </div>
                    </div>
                </MDBContainer>
            </div>

            {modalType === 'cbo' && <CBOLoginModel setPrevModal={setPrevModal} switchModal={setModalType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} toggleOpen={toggleOpen} />}
            {modalType === 'fpwd' && <ForgotPwdModel prevModal={prevModal} switchModal={setModalType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} toggleOpen={toggleOpen} />}
            {modalType === 'res' && <ResLoginModel setPrevModal={setPrevModal} switchModal={setModalType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} toggleOpen={toggleOpen} />}
            {modalType === 'resreg' && <ResRegModel switchModal={setModalType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} toggleOpen={toggleOpen} />}

            
        </>
    )
}

export default HomeHeadder
