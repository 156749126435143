import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { selectTokenData } from '../../../redux/authSlice';
import { tourOptions, tourSteps } from './UserTourConfig';

const UserTour = () => {

    const userData$ = useSelector(selectTokenData);
    const [userData, setUserData] = useState(userData$);

    useEffect(() => {
        const timeout = setTimeout(() => {
            startUserTour();
        }, 10000); //  - Adjust delay time to 10000 before moving the change
        return () => clearTimeout(timeout);
    }, [userData$, userData]);

    const startUserTour = () => {
        const steps = tourSteps(userData);
        const options = tourOptions(steps);

        const hasCompletedTour = sessionStorage.getItem('hasCompletedTour');
        if (!hasCompletedTour) {
            const intro = introJs();
            intro.setOptions(options);
            intro.onbeforechange(function (targetElement) {
                // Scroll to the target element before showing the tooltip
                // intro.scrollToElement(targetElement);
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
            intro.onexit(() => {
                sessionStorage.setItem('hasCompletedTour', true);
            });
            intro.start();
        }

    };

    return <></>
}

export default UserTour