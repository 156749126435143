import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const HvmMapTxt = () => {
    return (
      <>
        <p>Health Vulnerability Module is an indicator within the  Environmental Justice Index <Link to="/glossary" target="_blank"><u>(EJI)</u></Link>. For a better insight we are looking at each EJI indicator individually instead of the overall EJI Score.  </p>
        <p>Health vulnerability measures the prevalence of certain pre-existing health conditions, which can increase a person’s risk of morbidity and mortality due to environmental burden.</p>
        <p>Health Vulnerability Module were required to satisfy the following criteria:</p>
         <ol>
            <li>Indicator must represent a chronic health condition </li>
            <li> Indicator must represent a health condition that increases susceptibility to the negative health effects of environmental hazards and pollution </li>
        </ol>
        <p>Pre-existing Chronic Diseases measured under Health Vulnerability Modules are:  </p>
        <ul>
          <li>Asthma</li>
          <li>Cancer</li>
          <li>High Blood Pressure</li>
          <li>Diabetes </li>
          <li>Poor Mental Health</li>
        </ul>
        <p>HVM scores ranked between 0-1, with zero representing the lowest relative burden/vulnerability and 1 representing the highest relative burden/vulnerability. </p>
     </>
    )
  
}

export default HvmMapTxt
