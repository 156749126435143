import React from 'react'


const TriLineTxt = () => {
    return (
    <>
       <h5>Total emission and chemical share for last 10 years.</h5>
       <p style={{padding: "0px 32% 0px 0px"}}>The bar graph illustrates the emission trends over the past decade, offering a visual representation of the most significant pollutant in the area. </p>
    </>
    )
  
}

export default TriLineTxt