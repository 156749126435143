import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lastEventTime: null,
    isLocked: false,
    isSessionActive: false,
};

export const userSlice = createSlice({
    name: '[user]',
    initialState,
    reducers: {
        setLastEventTime: (state, action) => {
            state.lastEventTime = action.payload;
        },
        setIsLocked: (state, action) => {
            state.isLocked = action.payload;
        },
        setIsSessionActive: (state, action) => {
            state.isSessionActive = action.payload;
        },
    },
});


export const { 
    setLastEventTime, 
    setIsLocked, 
    setIsSessionActive
 } = userSlice.actions;

export const selectLastEventTime = state => state?.user?.lastEventTime;
export const selectIsLocked = state => state?.user?.isLocked;
export const selectIsSessionActive = state => state?.user?.isSessionActive;

export default userSlice.reducer;
