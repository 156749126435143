const chartsConfig = {
  sliseData: 25,
  pieChartTitle: 'Inventory of Emissions and Sinks:',
  trendsChartTitle: '',

  pie: {
      credits: false,
      chart: {
          plotBackgroundColor: "#fdc51e80",
          plotBorderWidth: null,
          type: 'pie',
          height: 350,
          width: 700, 
      },
      title: {
          text: 'Inventory of U.S. Greenhouse Gas Emissions and Sinks:',
          align: 'left'
      },
      tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      legend: {
          align: 'left',
          layout: 'vertical',
          verticalAlign: 'top',
          x: 15,
          y: 5
      }, 
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              showInLegend: true,
              dataLabels: {
                  enabled: false,
                  distance: 10,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  connectorColor: 'silver',
                  filter: {
                      property: 'percentage',
                      operator: '>',
                      value: 0
                  }
              }
          }
      },
      series: [{
          // name: 'Share',
          data: []
      }]
  },
  area: {
      credits: false,
      chart: {
          reflow: true,
          type: 'area'
      },
      title: {
          text: `{activeTab} of US ZIP Code {zipcode} from Large Fecilities 2013 - 2022`,
          align: 'left'
      },
  
      xAxis: {
          allowDecimals: false,
          accessibility: {
              rangeDescription: 'Range: {minyear} to {maxyear}.'
          }
      },
      yAxis: {
          title: {
              text: `{activeTab} Quantity (metric tons CO2)`
          }
      },
      tooltip: {
          backgroundColor: '#F9B837',
          title: '<b>{point.x}<b>',
          pointFormat: '{series.name} emissions in {point.x} <br/> were <b>{point.y:,.0f}</b>'
      },
      plotOptions: {
          area: {
              pointStart: new Date().getFullYear() - 10,
              showInLegend: false,

          }
      },
      series: []
  },
  tri: {
      credits: false,
      chart: {
          type: 'column'
      },
      title: {
          text: '{activeTab} of US ZIP Code {zipcode} from Large Fecilities',
          align: 'left'
      },
      // subtitle: {
      //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/landtransport/statistikk/innenlandsk-transport">SSB</a>',
      //     align: 'left'
      // },
      legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'top',
          x: 25,
          y: 15
      },
      xAxis: {
          categories: ['2019', '2020', '2021']
      },
      yAxis: {
          min: 0,
          title: {
              text: 'TRI Quantity (metric tons of CO2)'
          }
      },
      tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true,
                  format: '{point.percentage:.0f}'
              }
          }
      },
      series: [
          // {
          //     name: 'Road',
          //     data: [434, 290, 307]
          // }, {
          //     name: 'Rail',
          //     data: [272, 153, 156]
          // }, {
          //     name: 'Air',
          //     data: [13, 7, 8]
          // }, {
          //     name: 'Sea',
          //     data: [55, 35, 41]
          // }
      ]
  },

  gauge : {
    chart: {
      type: 'gauge',
      marginBottom: -20,
    },

    title: null,

    pane: {
      startAngle: -90,
      endAngle: 90,
      size: '80%',
      background: [{
        backgroundColor: {
          stops: [
            [0, '#55bc64'],
            [1, '#f6fbf6']
          ]
        },
        innerRadius: '73%',
        outerRadius: '15%',
        shape: 'arc',
        borderWidth: 0,
      }]
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      minorTickWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
      plotBands: [
        {
          from: 0,
          to: 16.6,
          color: '#ff5656', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'VHH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'left',
            x: 17,
          }
        },
        {
          from: 16.6,
          to: 33.3,
          color: '#ff8888', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'HH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'center',
            x: 27, 
          }
        },
        {
          from: 33.3,
          to: 50,
          color: '#fee114', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'MH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'right',
            y : 1,
            x : 2,
          }
        },
        {
          from: 50,
          to: 66.6,
          color: '#d1d80f', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'WPHL',
            text: 'LH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
        {
          from: 66.6,
          to: 83.3,
          color: '#84bd32', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'PC',
            text: 'VLH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
        {
          from: 83.3,
          to: 100,
          color: '#30ad43', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'LH',
            text: 'PC',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
      ],
    },

    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: false
        },
        dial: {
          radius: '70%',
          backgroundColor: 'black',
          topWidth: 0,
          baseWidth: 10,
          baseLength: '5%',
        },
        pivot: {
          radius: 10,
          backgroundColor: 'black',
        }
      }
    },

    series: [
      /*{
        name: 'Intensity',
        data: intensity !== null && intensity !== 0 ? [intensity] : [],
        tooltip: {
          valueSuffix: ' units',
        },
      },*/
    ],
    credits: false
  }
}

export default chartsConfig;