const tourSteps = (userData) => {
    let steps = [
        {
            step: 1,
            intro: 'Welcome to the dashboard, Let me show you around!',
        },
        {
            step: 2,
            intro: 'You can navigate to different dashboards from here!',
            element: document.querySelector('.ms-2'),
        },
        {
            step: 3,
            intro: 'You can find map view here!',
            element: document.querySelector('.map-img'),
        },
        {
            step: 4,
            intro: 'You can find trends here!',
            element: document.querySelector('.mb-3.trends-card'),
        },
        {
            step: 5,
            intro: 'You can find list view here!',
            element: document.querySelector('.mb-3.grid-card'),
        },
        {
            step: 6,
            intro: 'Click the dropdown here to see different data resources!',
            element: document.querySelector('.ripple.ripple-surface.px-2.py-2.w-100.border-bottom.border-0.rounded-0'),
        },
        {
            step: 7,
            intro: 'You can search zip code here!',
            element: document.querySelector('.txtbox'),
        },
        {
            step: 8,
            intro: 'If needed any help? You can check this out!',
            element: document.querySelector('.help-box'),
        },
    ];

    // Customize step 7 for CBO users
    if (userData.USER_TYPE === "CBO") {
        const stepIndex = steps.findIndex(step => step.step === 7);
        if (stepIndex !== -1) {
            steps[stepIndex] = {
                step: 7,
                intro: 'You can search for CBO here!',
                element: document.querySelector('.css-13cymwt-control'),
            };
        }
    }

    // Filter out steps where the element is null or undefined
    steps = steps.filter(step => {
        if (step.step === 1) {
            return true; // Always include step 1
        }
        return step.element && step.element !== null;
    });

    return steps;
};

const tourOptions = (steps) => ({
    exitOnEsc: false,
    exitOnOverlayClick: false,
    skipLabel: 'Skip',
    nextLabel: 'Next',
    prevLabel: 'Previous',
    doneLabel: 'Finish',
    showBullets: false,
    showProgress: true,
    showStepNumbers: true,
    disableInteraction: true,
    overlayOpacity: 0.5,
    steps: steps,
});

export { tourSteps, tourOptions };