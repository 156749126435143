import React from 'react'


const TriPieTxt = () => {
    return (
      <div>
        <h5>Facilities percentage share of total Emission -  Toxic Release</h5>
        <p>
            The pie chart provides a measure of the contributions to the emission from individual facilities within the designated area, offering a clear visualization of which facility is the primary emitter of TRI chemicals.  
        </p>
  </div>
    )
  
}

export default TriPieTxt