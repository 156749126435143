import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    props: null,
    bredcrumbs: [],
    isMobileMenu: false,
    showLeftnav: true,
    miniLeftNav: false,
    zipCode: null,
    isZipSearching: false,
    isLeftnavUpdated: false,
    isLeftnavUpdated2: true,
    chemicalId : null,
    selectedCBO: null,
    chemicalName : null,
    hasChemicals: false,
    ischemicalsDDState: false
};

export const appSlice = createSlice({
    name: '[app]',
    initialState,
    reducers: {
        setProps: (state, action) => {
            state.props = action.payload;
        },

        setBredcrumbs: (state, action) => {
            state.bredcrumbs = action.payload;
        },

        setShowLeftnav: (state, action) => {
            if (action.payload !== undefined) {
                state.showLeftnav = action.payload;
            }else{
                state.showLeftnav = !state.showLeftnav;
            }
        },

        setMiniLeftNav: (state, action) => {
            if (action.payload) {
                state.miniLeftNav = action.payload;
            }else{
                state.miniLeftNav = !state.miniLeftNav;
            }
        },

        setIsMobileMenu: (state, action) => {
            state.isMobileMenu = action.payload;
        },

        setZipCode: (state, action) => {
            state.zipCode = action.payload;
        },
        setSelectedCBO: (state, action) => {
            state.selectedCBO = action.payload;
        },

        setIsZipSearching: (state, action) => {
            state.isZipSearching = action.payload;
        },
        setIsLeftnavUpdated: (state, action) => {
            state.isLeftnavUpdated = action.payload;
        },
        setChemicalId: (state, action) => {
            state.chemicalId = action.payload;
        },
        setChemicalName: (state, action) => {
            state.chemicalName = action.payload;
        },
        setHasChemicals: (state, action) => {
            state.hasChemicals = action.payload;
        },

        setIschemicalsDDState: (state, action) => {
            state.ischemicalsDDState = action.payload;
        }
    },
});


export const { setProps, setBredcrumbs, setShowLeftnav, setMiniLeftNav, setIsMobileMenu, 
            setZipCode, setIsZipSearching, setIsLeftnavUpdated, setSelectedCBO, 
            setChemicalId, setChemicalName, setHasChemicals, setIschemicalsDDState } = appSlice.actions;

export const selectProps = (state) => state.app.props;
export const selectBredcrumbs = (state) => state.app.bredcrumbs;
export const selectShowLeftnav = (state) => state.app.showLeftnav;
export const selectMiniLeftNav = (state) => state.app.miniLeftNav;
export const selectIsMobileMenu = (state) => state.app.isMobileMenu;
export const selectZipCode = (state) => state.app.zipCode;
export const selectIsZipSearching = (state) => state.app.isZipSearching;
export const selectIsLeftnavUpdated = (state) => state.app.isLeftnavUpdated;
export const selectChemicalId = (state) => state.app.chemicalId;
export const selectSelectedCBO = (state) => state.app.selectedCBO;
export const selectChemicalName = (state) => state.app.chemicalName;
export const selectHasChemicals = (state) => state.app.hasChemicals;
export const selectIschemicalsDDState = (state) => state.app.ischemicalsDDState;

export default appSlice.reducer;
