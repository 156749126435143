import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBTextArea, MDBSpinner } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { selectToken, selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Aboutus.css';
import CopyrightsFooter from '../CopyrightsFooter/CopyrightsFooter';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({ email: '', subject: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector(selectToken);
  const userData = useSelector(selectTokenData);

  const validateFields = () => {
    const errors = {};
    if (!email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid.';
    }

    if (!subject) {
      errors.subject = 'Subject is required.';
    }

    if (!message) {
      errors.message = 'Message is required.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const postComment = (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    const data = {
      USER_ID: userData['ID'],
      EMAIL: email,
      SUBJECT: subject,
      DESC: message,
    };

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    setIsLoading(true);
    fetch(`${appConfig.apiBaseUrl}/comments/save`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode >= 400) {
          setErrors({ message: result.errMessage });
          console.log(result);
        } else {
          toast.success('Your Message Submitted successfully');
          setEmail('');
          setSubject('');
          setMessage('');
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <MDBRow className='bg-body-tertiary mb-4'>
        <MDBCol md='6' offsetMd='3'>
          <div className='form-wraper'>
            <form onSubmit={postComment}>
              <h4>Contact Us</h4>
              <p>Please fill out the form below with your inquiries, comments, or feedback, and we'll get back to you as soon as possible.</p>
              <MDBInput
                type='email'
                id='form4Example2'
                onChange={(e) => setEmail(e.target.value)}
                label='Email address'
                value={email}
              />
              {errors.email && <div className="text-danger mb-2">{errors.email}</div>}
              <MDBInput
                id='Subject'
                wrapperClass='mt-4'
                onChange={(e) => setSubject(e.target.value)}
                label='Subject'
                value={subject}
              />
              {errors.subject && <div className="text-danger mb-2">{errors.subject}</div>}
              <MDBTextArea
                label='Message'
                id='textAreaExample'
                onChange={(e) => setMessage(e.target.value)}
                rows={10}
                value={message}
                wrapperClass='mt-4'
              />
              {errors.message && <div className="text-danger mb-3">{errors.message}</div>}
              <div className='d-flex flex-row-reverse'>
                <MDBBtn type='submit' className='mb-4 mt-4 p-2'>
                  {isLoading && <MDBSpinner size='sm' className='mr-1' role='status' tag='span' />}
                  Send Now
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow> 
      <CopyrightsFooter />
    </>
  );
};

export default ContactUs;