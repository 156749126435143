import React from 'react'

const WhatsTheFunctionComponent = () => {
    return (
        <>
            <div className="main-sec bg-blue steps-con">
                <div className="container pt-50 pb-50">
                    <div className="row text-center mb-50">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <span className="section-title__tagline c-white">Exploring Data Insights</span>
                            <h3 className="abt-title c-white">How it works</h3>
                        </div>
                    </div>

                    <div className="row gx-md-5 gy-5 mb-md-18 pl-30 pt-20">

                        <div className="col-md-6 col-lg-3 step-cord">
                            <span>01</span>
                            <img className="img-fluid"
                                src="https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/arrow-down.png" alt="" />
                            <h4>Search by ZIP Code </h4>
                            <p className="mb-3 c-white">Using the Search bar in Hub / Explore Data to search for datasets by the zip codes.</p>
                        </div>

                        <div className="col-md-6 col-lg-3 step-cord">
                            <span>02</span>
                            <img className="img-fluid"
                                src="https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/arrow-up.png" alt="" />
                            <h4>Explore Data Sets</h4>
                            <p className="mb-3 c-white">Navigate to the specific category of data that you are interested in looking for.</p>
                        </div>

                        <div className="col-md-6 col-lg-3 step-cord">
                            <span>03</span>
                            <img className="img-fluid"
                                src="https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/arrow-down.png" alt="" />
                            <h4>Get Facility Information</h4>
                            <p className="mb-3 c-white">Select the category to the data hub resource tab to see currently available qualifying datasets</p>
                        </div>

                        <div className="col-md-6 col-lg-3 step-cord">
                            <span>04</span>
                            <h4>Help Communities</h4>
                            <p className="mb-3 c-white">This comprehensive dataset allows users to examine the intersection of environmental factors and social disparities.</p>
                        </div >

                    </div >
                </div >

            </div >
        </>
    )
}

export default WhatsTheFunctionComponent
