import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const HealthImpactTxt = () => {
    return (
      <>
        <p>In our initial phase, we've compiled a list of 152 toxic chemicals from the EPA and are analyzing the health impacts associated with these chemicals. We will only present data for chemicals found within the search boundary (zip codes or member CBO boundary).</p>
        <p>The impact depicted is based on data from <Link to="https://pharos.habitablefuture.org/" target="_blank"><u>PHAROS</u></Link>. The health impact is visualized using a speedometer-like gauge, where green indicates safe or low impact, while red signifies toxic or very high impact.  </p>
        <p>We are considering 7 major human health impacts due to chemical release. Each health impact is defined here. You can select the chemical from the top right corner <img src='../images/health-impact-icon.png' alt='Health Impact' /> to learn about the specific chemical’s health impact.  
        </p> 
      </>
    )
  
}

export default HealthImpactTxt
