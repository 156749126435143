import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';

import './Aboutus.css'

const Tutorials = () => {
    return (
    <>

<MDBRow>
    
        <MDBCol size='md'>
        <div className='info-box p-4'>
           <h3>Tutorials</h3>
           <p>Available Soon...</p>
        </div>
        </MDBCol>
       
      </MDBRow>
       
    </>
    )
  
}

export default Tutorials