import { MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'

const ExploreComponent = () => {
    return (
        <>
            <MDBContainer>
                <div className="main-sec">
                    <div className="container">
                        <div className="row text-center mb-50">
                            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                <span className="section-title__tagline">EXPLORE</span>
                                <h3 className="abt-title">Featured Dashboards</h3>
                                <p>We are displaying three major indicators in our data set. Environmental Burden Module, <br/> Social Vulnerability Module, and Health Vulnerability Module. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                            <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                           
                                <div className="card-body pt-50 pb-50 ">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon3.png"
                                        alt="" />
                                    <h4>GHG</h4>
                                    <p className="mb-2">Explore locations where industrial facilities release greenhouse gas (GHG)... </p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#799b3e'}}>
                                        <h3 className="text-white fs-5">Greenhouse Gas</h3>
                                        <p className="text-white">Explore locations where industrial facilities release greenhouse gas (GHG) emissions, contributing to the climate crisis. This data can be used to help understand emission sources and trends, assess reduction goal progress, and develop policies and strategies.</p>
                                    </div>
                                </div>
                           
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon2.png"
                                        alt="" />
                                    <h4>TRI</h4>
                                    <p className="mb-2">Explore location data on toxic chemical releases. This data can be used to track...</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#f05424'}}>
                                        <h3 className="text-white fs-5">Toxic Release Inventory</h3>
                                        <p className="text-white">Explore location data on toxic chemical releases. This data can be used to track pollution, study health impacts, raise community awareness, enforce regulations, and shape policies.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon-svi.png"
                                        alt="" />
                                    <h4>SVI</h4>
                                    <p className="mb-2">Assess an area’s vulnerability to natural disasters and other hazards.</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#b17029'}}>
                                        <h3 className="text-white fs-5">Social Vulnerability Index</h3>
                                        <p className="text-white">Assess an area’s vulnerability to natural disasters and other hazards. This data can be used to identify that area’s need for support and guide resource allocation for disaster preparedness and response.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        {/* <div className="col-md-6 col-xl-3 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon-svm.png"
                                        alt="" />
                                    <h4>SVM</h4>
                                    <p className="mb-2">The map represents Social Vulnerability Module Indicators...</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#f4a71e'}}>
                                        <h3 className="text-white fs-5">SVM</h3>
                                        <p className="text-white">The map represents Social Vulnerability Module Indicators, are intended to capture population characteristics.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-3 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon-hvm.png"
                                        alt="" />
                                    <h4>HVM</h4>
                                    <p className="mb-2">Capture the prevalence of certain pre-existing health conditions. </p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#199ad6'}}>
                                        <h3 className="text-white fs-5">HVM</h3>
                                        <p className="text-white">Indicators characterizing health vulnerability are intended to capture the prevalence of certain pre-existing health conditions. </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div> */}

                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon-ebm.png"
                                        alt="" />
                                    <h4>EJI</h4>
                                    <p className="mb-2">Explore environmental justice disparities within communities and...</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#fd8e9d'}}>
                                        <h3 className="text-white fs-5">Environmental Justice Index</h3>
                                        <p className="text-white">Explore environmental justice disparities within communities and pinpoint areas facing disproportionate environmental burdens. This data can be used to guide policy-making, resource allocation, and community advocacy efforts for equitable environmental protection and public health initiatives, <strong>EBM, HVM and SVM</strong> </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon4.png"
                                        alt="" />
                                    <h4>CEJST</h4>
                                    <p className="mb-2">Identify how communities rank in their experience of cumulative burdens...</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#00bcd4'}}>
                                        <h3 className="text-white fs-5">Climate and Economic Justice Screening Tool</h3>
                                        <p className="text-white">Identify how communities rank in their experience of cumulative burdens in the categories of climate change, energy, health, housing, legacy pollution, transportation, water and wastewater, and workforce development. This data can be used to help identify disadvantaged communities that will benefit from federal investment programs.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4 mb-30 flip-container">
                        <div className='flip-box'>
                            <div className="card card2 shadow-lg card-hover flip-box-body">
                                <div className="card-body pt-50 pb-50">
                                    <img className="mb-30"
                                        src="/images/dashboard-icon5.png"
                                        alt="" />
                                    <h4>PHAROS</h4>
                                    <p className="mb-2">Identifying and assessing the toxicity and potential hazards of various...</p>
                                </div>
                                <div className="content d-flex flex-column align-items-center justify-content-center">
                                    <div style={{background: '#009688'}}>
                                        <h3 className="text-white fs-5">PHAROS</h3>
                                        <p className="text-white">Explore this comprehensive, independent database identifying and assessing the toxicity and potential hazards of various chemicals and materials. This data can be used to advocate for safer practices, educate community members on potential hazards, and engage with local authorities and decision-makers.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                    

                    </div>

                </div>
            </MDBContainer>
        </>
    )
}

export default ExploreComponent
