import React, { useEffect, useState } from 'react'
import Topbar from '../topBar/Topbar'
import LeftNav from '../leftNav/LeftNav'
import './AppLayout.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsMobileMenu, selectMiniLeftNav, } from '../../../redux/appSlice'
import { login, logout, selectToken, selectUserMode } from '../../../redux/authSlice'
import { selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config'
import jwtDecode from 'jwt-decode'
import { Outlet, useNavigate } from 'react-router-dom'
import UserTour from '../userTour/UserTour'

const AppLayout = () => {
    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const isMobileMenu = useSelector(selectIsMobileMenu);
    const userData = useSelector(selectTokenData);
    const token$ = useSelector(selectToken);
    const userMode$ = useSelector(selectUserMode);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userData) {
            if (userData.USER_TYPE === "CBO") {
                if (userData.COMUNITY_ID) {
                    appConfig.defaultZipCode = userData.COMUNITY_ID;
                } else {
                    appConfig.defaultZipCode = appConfig.defaultComunityId;
                }
            } else if (userData.USER_TYPE === "RESIDENT") {
                if (userData.ZIPCODE) {
                    appConfig.defaultZipCode = userData.ZIPCODE;
                }
            }
        }
    }, [userData]);
    useEffect(() => {
        // get expairy time of token
        autoRefreshToken();
        setInterval(() => {
            autoRefreshToken();
        }, (1000 * 60 * 20));
    }, []);

    const autoRefreshToken = () => {
        if (userData.USER_TYPE === "RESIDENT") return;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token$}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/refreshtoken`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    // setIsAuthenticated(false);
                    dispatch(logout())
                } else {
                    const data = jwtDecode(result.token);
                    dispatch(login({
                        token: result.token,
                        tokenData: data,
                        mode: userMode$
                    }));
                }
            })
            .catch(error => {
                // setIsAuthenticated(false);
            });
    }

    return (
        <div className='app-container'>
            <Topbar />
            <div className='app-body' >
                <LeftNav />
                {/* <StickyMenu /> */}
                <div className='work-area scrollbar scrollbar-default' style={isMobileMenu ? {} : isMiniLeftnav ? {
                    width: 'calc(100% - 80px)',
                    marginLeft: '80px',
                    transition: 'all 0.3s ease-in-out'
                } : {
                    width: 'calc(100% - 240px)',
                    marginLeft: '240px',
                    transition: 'all 0.3s ease-in-out'
                }}>
                    <div style={{
                        // height: '2400px',
                        width: '100%',
                    }}>
                        <Outlet />
                    </div>
                </div>
            </div>
            <UserTour />
        </div>
    )
}

export default AppLayout
