import React from 'react'
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import './Auth.css'

import Login from '../../modules/auth/login/Login';
import WildComponent from '../../modules/common/wildComponent/WildComponent';
import CircularProgress from '../../modules/common/progress/Circular';
import HomePage from '../../modules/home/homePage';
import appConfig from '../../configs/App.config';

const AuthLayout = () => {


    return (
        <>
            {/* <WildComponent >
                <div className="loading">
                    <CircularProgress Width="100px" Height="100px" />
                </div>
            </WildComponent> */}
            <Outlet />
        </>
    )
}

export default AuthLayout
