import React, { useEffect, useState } from 'react';
import './cboSearch.css';
import AsyncSelect from 'react-select/async';
import appConfig from '../../../configs/App.config';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, selectTokenData } from '../../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { selectSelectedCBO, setSelectedCBO, setZipCode } from '../../../redux/appSlice';

export default function CBOSearch() {
    const [cboTerm, setCboTerm] = useState('');
    const [cbos, setCbos] = useState([]);
    const token$ = useSelector(selectToken);
    const userData$ = useSelector(selectTokenData);
    const selectedCBO$ = useSelector(selectSelectedCBO);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        doCBOSearch({ keyCode: 13 })
    }, []);

    const doCBOSearch = (e) => {
        if (e.keyCode === 13) {
            const options = {
                method: "POST",
                redirect: "follow",
                headers: new Headers(),
                body: JSON.stringify({
                    TERM: cboTerm
                })
            };
            options.headers.append("Content-Type", "application/json");
            options.headers.append('Access-Control-Allow-Origin', "*");
            options.headers.append("Authorization", `Bearer ${token$}`)
            // call API to search CBOs
            fetch(`${appConfig.apiBaseUrl}/admin/users/all`, options)
                .then(response => response.json())
                .then(data => {
                    // const finalRes = data.filter((item) => item.ID !== userData$.ID).map((item) => {
                    const finalRes = data.map((item) => {
                        return {
                            label: item.FULLNAME,
                            value: `${item.COMUNITY_ID}|${item.ID}`,
                            userObj: item
                        }
                    });

                    setCbos(finalRes);
                })
                .catch(error => {
                    toast.error('Error searching CBOs', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                )
                .finally(() => {
                    setCboTerm('');
                });
        }

        return [];
    }

    const loadOptions = (inputValue, callback) => {
        setCboTerm(inputValue);
        callback(() => {
            if (inputValue) {
                return cbos.filter(i => {
                    return i.label.toLowerCase().includes(inputValue.toLowerCase());
                });
            } else {
                return cbos;
            }
        });
    }

    const handleCboChange = (selectedOption) => {
        let selectedVals = (selectedOption)? selectedOption.value.split('|') : [];
        const matchTxtx = `${userData$.COMUNITY_ID}|${userData$.ID}`;
        if (!selectedOption) {
            dispatch(setZipCode(userData$.COMUNITY_ID));
            dispatch(setSelectedCBO(null));            
            // navigate(`/app/dashboard`)
        } else if(selectedOption.value === matchTxtx){
            if(userData$.ID === selectedOption.userObj.ID){
                dispatch(setZipCode(selectedVals[0]));
                dispatch(setSelectedCBO(null));
            }else{
                dispatch(setZipCode(selectedVals[0]));
                dispatch(setSelectedCBO(selectedOption.userObj));
            }
            // navigate(`/app/dashboard`)
        }else {

            dispatch(setZipCode(selectedVals[0]));
            dispatch(setSelectedCBO(selectedOption.userObj));
            // navigate(`/app/search/${selectedOption.value}`)
        }
    }

    return (
        <>
            <div className='cbo-search'>
                {cbos.length > 0 &&
                    <AsyncSelect
                        className='dbo-dd'
                        cacheOptions
                        defaultOptions
                        onKeyDown={doCBOSearch}
                        loadOptions={loadOptions}
                        onChange={handleCboChange}
                        isClearable={true}
                        value={selectedCBO$ ? { label: selectedCBO$.FULLNAME, value: `${selectedCBO$.COMUNITY_ID}|${selectedCBO$.ID}` } : null}
                        placeholder='CBO Boundary'

                    />
                }

            </div>
        </>
    );
}
