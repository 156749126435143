import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const SviMapTxt = () => {
    return (
      <>
        <p><strong>Social vulnerability</strong> refers to the potential negative effects on communities caused by external stresses on human health. The <Link to="https://www.atsdr.cdc.gov/placeandhealth/svi/index.html" target='_blank'><u>CDC/ATSDR SVI</u></Link> uses U.S. Census data to determine the social vulnerability of every census tract.  The SVI ranks each tract on 16 social factors, including poverty, lack of vehicle access, and crowded housing, and groups them into four related themes.  </p>
        <p>This map shows the overall Social Vulnerability Index score  of census tracts. The overall SVI score ranges from 0 (lowest vulnerability) to 1 (highest vulnerability). The four cutoff points for the SVI score are <strong>0-0.25, 0.25-0.50, 0.50-0.75, and 0.75-1.0.</strong></p>
        <p><strong>Uses of SVI:</strong></p>
        <ul>
            <li>To categorize communities that will need continued support to recover following an emergency or natural disaster. </li>
            <li>To guide community-based health promotion initiatives.  </li>
            <li>To help public health officials and emergency response planners decide the number of emergency personnel needed, plan the best way to evacuate people, and account for socially vulnerable populations. </li>
            <li>To identify areas in need of emergency shelters and estimate the amount of supplies needed. </li>
        </ul>
     </>
    )
  
}

export default SviMapTxt
