import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './Login.css'
import { login, logout, selectToken } from '../../../redux/authSlice';
import CircularProgress from '../../common/progress/Circular';
import jwt_decode from 'jwt-decode';
import appConfig from '../../../configs/App.config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faHomeAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
// import styled from '@emotion/styled';

const Login = () => {
    // const count = useSelector(selectCount);
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [gotoDashboard, setGotoDashboard] = useState(false);
    //const [ticker, setTicker] = useState(null);
    const [token, setToken] = useState(null);
    const [ticks, setTicks] = useState(0);
    const errMsg = 'Token is invalid';
    let ticker = null;
    const navgate = useNavigate();

    const token$ = useSelector(selectToken);
    // let ticks = 0;

    useEffect(() => {
        let newToken = null;
        let mode = "F";
        const hash = window.location.hash;
        const urlParts = {};
        if (hash) {
            const hashParts = hash.split('&');
            for (let i = 0; i < hashParts.length; i++) {
                const subparts  = hashParts[i].split('=');
                if(subparts[0][0] === '#'){
                    subparts[0] = subparts[0].substring(1, subparts[0].length);
                }
                urlParts[subparts[0]] = subparts[1];              
            }
            newToken = urlParts.token;
            mode = urlParts.m || "F";
            setToken(newToken);
        }
        if (!newToken) {
            newToken = token$;
        }
        if (!newToken) {
            setShowError(true);
            navgate('/home');
        } else {
            if(token$ == newToken){
                setShowError(false);
                setIsSuccess(false);
                setGotoDashboard(true);
                navgate('/');
            }else{
                setShowError(false);
                const data = jwt_decode(newToken);                
                dispatch(login({
                    token: newToken,
                    tokenData: data,
                    mode: mode
                }));
                setShowError(false);
                setIsSuccess(false);
                setGotoDashboard(true);
                navgate('/');
            }
        }


    }, []);
    

    const refreshToken = (token) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/checklogin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    return false;
                } else {
                    return result;
                }
            })
            .catch(error => console.log('error', error));
    }

    const setTimer = () => {
        ticker = setInterval(() => {
            setTicks(ticks => ticks + 1);
            if (ticks >= 3) {
                window.location.replace(appConfig.homeUrl);
                clearInterval(ticker);
            }
        }, 1000);
    }

    return (
        <div className='login-form'>
            <div className="login-form-container">
                <div className="login-form-header">
                    <CircularProgress Width="100px" Height="100px" />
                </div>
            </div>
            {/* {showError && <Navigate to={appConfig.publicSiteUrl} replace={true} />} */}
            {/* {showError && console.log('showError'+ showError)} */}
            {/* {showError && window.location.replace(appConfig.publicSiteUrl)} */}
            {/* {isSuccess && console.log('isSuccess:'+ isSuccess)} */}
            {/* {isSuccess && <Navigate to="/auth" replace={true} />} */}
            {/* {gotoDashboard && console.log('gotoDashboard:'+ gotoDashboard)} */}
            {/* {gotoDashboard && <Navigate to="/dashboard" replace={true} />} */}
        </div>
    )
}

export default Login;
