import React from 'react'


const lineTxt = () => {
    return (
    <>
       <h5>Total Greenhouse Gas Emission for last 10 years</h5>
       <p style={{padding: "0px 32% 0px 0px"}}>The line graph shows the trend of emissions over the past decade, providing a visual representation of how emissions have fluctuated over time.</p>
    </>
    )
  
}

export default lineTxt