import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const GhgMapTxt = () => {
    return (
      <>
        <p>The <strong>GHGRP</strong> (Greenhouse Gas Reporting Program) mandates the reporting of <a href="/glossary" target="_blank"><u>Greenhouse Gas (GHG)</u></a>  data and other pertinent information from major GHG emitters, fuel and industrial gas suppliers, and CO2 injection sites in the United States. GHG are those gasses that trap heat in the atmosphere and include carbon dioxide, methane, nitrous oxide, and fluorinated gases. GHG causes climate change by trapping heat, and it also contributes to respiratory diseases from smog and air pollution.</p>
        <p>This map displays greenhouse gas emissions from large industries in census tracts with minority and poverty population data. The purple boundary <img src='../images/zip-boundry-icon.png' alt='zip code boundry' /> on the map indicates the defined area of interest, which can be a specific zip code or community’s boundary. </p>
        <p>The colored polygons <img src='../images/polygons-icon.png' alt='zip code boundry' /> depict census tracts within the specified boundaries, showing the percentage of the population who are <a href='/glossary' style={{textDecoration: "underline"}} target="_blank" >minorities</a> or living below the <a href="/glossary" style={{textDecoration: "underline"}} target="_blank" >poverty line</a> . By clicking on the tab <img src='../images/map-select.png' className="img-fluid" alt="" />  in the left-hand corner, users can display GHG emission data releases related to the poverty and minority data.  </p>
        <p><i className="fas fa-play" style={{transform: "rotate(30deg)",  color: "#0767d7"}}></i> Triangles on the map indicate the locations of facilities or industries emitting greenhouse gases. <i className="fas fa-circle" style={{color: "#24c63c"}}></i> The size of the green circles around the triangles gives a measure of how much GHG is being emitted at the facility. The legend on the left side of the map provides the range of GHG releases in metric tons.  </p>
        <p><strong>Note :</strong> Get more information by clicking on the specific triangle or circle about that particular facility. </p>
        <p>The timeline at the bottom provides the emission indicators for the last 10 years. Users can explore and see the trends of GHG releases over these 10 years.</p>
      </>
    )
  
}

export default GhgMapTxt