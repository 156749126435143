import React from 'react'


const TriTableTxt = () => {
    return (
    <>
       <h5 className="card-title border-dark  m-0">Facilities percentage share of total Emission - Toxic Release Inventory for last 10 years<span className="badge badge-primary mx-2 ms-2">Units in Metric Tons</span></h5>
       <p style={{padding: "5px 25% 0px 0px"}}>The table below presents the emissions from each facility over the last decade, providing insight into the efforts made by each facility to reduce their emissions. Clicking on the <img src='../images/table-icon.png' alt='zip code boundry' /> sign within the table will display the amount of each toxic chemical emitted from that facility.</p>
    </>
    )
  
}

export default TriTableTxt