import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import CopyrightsFooter from '../CopyrightsFooter/CopyrightsFooter';

import './Aboutus.css'

const Glossary = () => {
    return (
    <>

<MDBRow className='mb-3'>
    
        <MDBCol size='md'>
        <div className='info-box p-4'>
                <h3 className="abt-title">Glossary and Abbreviations</h3>
                <ul className="info-list">
                <li>
                    <strong>CEJST:</strong> Climate and Economic Justice
                    Screening Tool
                  </li>
                  <li>
                    <strong>EBM:</strong> Environmental Burden Module under EJI
                  </li>
                  <li>
                    <strong>EJDAP:</strong> Environmental Justice Data Action
                    Portal
                  </li>
                  <li>
                    <strong>EJI:</strong> Environmental Justice Index
                  </li>
                  <li>
                    <strong>GHG:</strong> Greenhouse Gases
                  </li>
                  <li>
                    <strong>HVM:</strong> Health Vulnerability Module under EJI
                  </li>
                  <li>
                    <strong>SVI:</strong> Social Vulnerability Index
                  </li>
                  <li>
                    <strong>SVM:</strong> Social Vulnerability Module under EJI
                  </li>
                  <li>
                    <strong>TRI:</strong> Toxic Release Inventory
                  </li>
                  
                </ul>

                <h4>Definitions </h4>
                <p>
                  <strong>Below Poverty Line:</strong>
                  <br /> Poverty thresholds and poverty guidelines are dollar
                  amounts set by the U.S. government to indicate the least
                  amount of income a person or family needs to meet their basic
                  needs. People whose income falls below the specified amount
                  are considered poor.
                </p>
                <p>
                  <strong>Carcinogen:</strong>
                  <br />
                  A substance, organism or agent capable of causing cancer. Carcinogens may occur naturally in the environment (such as ultraviolet rays in sunlight and certain viruses) or may be generated by humans (for example, automobile exhaust fumes, pollutants from factories, cigarette smoke).    
                  <br /> <Link to="https://www.genome.gov/genetics-glossary/Carcinogen" target='_blank'><u>https://www.genome.gov/genetics-glossary/Carcinogen</u></Link>
                </p>
                <p>
                  <strong>CEJST:</strong>
                  <br /> The Climate and Economic Justice Screening Tool was developed under <Link to="https://www.federalregister.gov/documents/2021/02/01/2021-02177/tackling-the-climate-crisis-at-home-and-abroad#:~:text=Sec.%20223.%20Justice40,40-percent%20goal." target='_blank'><u>Executive Order 14008,</u></Link> issued by President Biden in January of 2021, which directed the Council on Environmental Quality (CEQ) to develop a new tool. The tool has an interactive map and uses datasets that are indicators of burdens in eight categories: climate change, energy, health, housing, legacy pollution, transportation, water and wastewater, and workforce development. CEJST uses this information to identify communities that are experiencing these burdens. These communities are disadvantaged because they are overburdened and underserved. A major disadvantage of CEJST is that it does not use racial demographic data.
                  <br /> <Link to="https://screeningtool.geoplatform.gov/en/about" target='_blank'><u>https://screeningtool.geoplatform.gov/en/about</u></Link>
                </p>
                <p>
                  <strong>Census Tract:</strong>
                  <br /> The smallest subdivisions of land for which demographic
                  and health data are consistently available. Each census tract
                  is part of a particular county and is home to an average of
                  4,000 people.
                </p>
                <p>
                  <strong>Developmental Toxicant:</strong>
                  <br /> A toxic substance that can cause harm to a developing child, including birth defects, low birth weight, and biological or behavioral problems that appear as the child grows.
                  <br/> <Link to="https://www.sciencedirect.com/topics/pharmacology-toxicology-and-pharmaceutical-science/developmental-toxicity" target='_blank'><u>https://www.sciencedirect.com/topics/pharmacology-toxicology-and-pharmaceutical-science/developmental-toxicity</u></Link>
                </p>
                <p>
                  <strong>Environmental Justice Index:</strong>
                  <br /> Environmental Justice Index (EJI), is a place-based tool designed to measure the cumulative impacts of environmental burden through the lens of human health and health equity. We are displaying three major indicators in our dataset; Environmental Burden Module, Social Vulnerability Modul, and Health Vulnerability Module.
                </p>

                <p>
                  <strong>GHG:</strong>
                  <br /> Greenhouse gases (GHGs) are gases in the earth's atmosphere that trap heat.
                </p>
                <p>
                  <strong>Minority Population:</strong>
                  <br /> A national minority is a social group within a state that differs from the majority and/or dominant population in terms of ethnicity, language, culture, or religion.
                </p>
                <p>
                  <strong>Neurotoxicity - Single Exposure:</strong>
                  <br /> <Link to="https://www.sciencedirect.com/topics/medicine-and-dentistry/neurotoxicity" target='_blank'><u>Neurotoxicity</u></Link> refers to the direct or indirect effect of chemicals that disrupt the <Link to="https://www.sciencedirect.com/topics/medicine-and-dentistry/nervous-system" target='_blank'><u>nervous system</u></Link> of humans. There may be a significant delay between exposure and appearance of symptoms. Neurotoxicity usually resolves by itself after exposure to the chemicals stops and rarely progresses without continued exposure.
                </p>
                <ul className="info-list">
                  <li>
                    <strong>Eye Irritation:</strong> Refers to feelings of dryness, itchiness, pain, or grittiness in the eyes. Sometimes, it might feel as though there is something in the eye. The eye might be red or swollen.
                    <br /><Link to="https://www.aao.org/eye-health/symptoms/irritation" target='_blank'><u> https://www.aao.org/eye-health/symptoms/irritation</u></Link>
                  </li>
                  <li>
                    <strong>Respiratory Sensitization:</strong> Respiratory sensitizer is a chemical (substance) that when inhaled can trigger an irreversible allergic reaction of the airways (respiratory system). Respiratory sensitization is an immunological response (how your body recognizes and defends itself) to previous exposure to a chemical. Asthma is the most common health condition that results from respiratory sensitization. Other allergic reactions include rhinitis (inflammation in the nose), conjunctivitis (inflammation of the eye and eyelid), or alveolitis (inflammation of the lungs).
                    <br /> <Link to="https://www.schc.org/assets/docs/ghs_info_sheets/respiratory_sensitization_2017-04.pdf" target='_blank'><u>https://www.schc.org/assets/docs/ghs_info_sheets/respiratory_sensitization_2017-04.pdf</u></Link>
                  </li>
                  <li>
                    <strong>Skin Irritation:</strong> The noticeable change in the skin in terms of texture and color (e.g., red, inflamed, and bumpy skin) when the skin comes into contact with a substance or product.
                    <br /> <Link to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8659814/" target='_blank'><u></u>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8659814/</Link>
                 </li>
                </ul>
                <p>
                  <strong>PHAROS:</strong>
                  <br /> Pharos is the industry’s most comprehensive, independent database of chemicals, polymers, metals and their associated human and environmental health hazards.
                </p>
                <p>
                  <strong>Reproductive Toxicant:</strong>
                  <br />
                  A toxic substance that can disrupt the male or female reproductive systems, including decreased fertility, disrupt sexual development and function, and result in loss of a fetus during pregnancy.
                  <br /> <Link to="https://www.sciencedirect.com/topics/pharmacology-toxicology-and-pharmaceutical-science/reproductive-toxicity" target='_blank'><u>https://www.sciencedirect.com/topics/pharmacology-toxicology-and-pharmaceutical-science/reproductive-toxicity</u></Link>
                </p>
                <p>
                  <strong>Social Vulnerability Index:</strong>
                  <br /> Social vulnerability refers to the potential negative
                  effects on communities caused by external stresses on human
                  health.
                </p>
                <p>
                  <strong>TRI:</strong>
                  <br /> The Toxics Release Inventory (TRI) Program tracks the
                  industrial management of toxic chemicals that may cause harm
                  to human health and the environment.
                </p>

                <ul className="info-list">
                  <li>
                    <strong>Environmental Burden -</strong> All features of the
                    environment, both positive and negative, that contribute to
                    human and environmental health. 
                  </li>
                  <li>
                    <strong>Health Vulnerability -</strong> Intrinsic biological factors such as chronic, pre-existing conditions that can worsen the effects of environmental burden.
                  </li>
                  <li>
                    <strong>Social Vulnerability -</strong> The combined demographic and socioeconomic factors that adversely affect communities that encounter hazards and other community-level stressors.
                  </li>
                </ul>

                
                
             
        </div>
        </MDBCol>
       
      </MDBRow>
      <CopyrightsFooter />
       
    </>
    )
  
}

export default Glossary