import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const EbmMapTxt = () => {
    return (
      <>
        <p><strong>Environmental Burden Module</strong> (EBM) is an indicator within the <Link to="https://www.atsdr.cdc.gov/placeandhealth/eji/index.html" target='_blank'><u>Environmental Justice Index (EJI)</u></Link>. For a better insight, we are looking at each EJI indicator individually instead of the overall EJI Score.  </p>
        <p>Environmental Burden Module  (EBM) indicators represent environmental burdens are characteristics of the environment that contribute either negatively or positively to human health and well-being.</p>
        <p><strong>All indicators included in the Environmental Burden Module satisfied the following criteria:</strong></p>
        <ul>
            <li>The presence or absence of the environmental characteristics represented by this variable has a quantifiable negative effect on human health </li>
            <li>The mechanism by which the presence or absence of the environmental characteristics represented by this variable affects health is understood  </li>
            <li>The environmental characteristics represented by this variable are not already represented within another environmental burden variable </li>
        </ul>
        <p>EBM scores are ranked between 0-1, with zero representing the lowest relative burden/vulnerability and 1 representing the highest relative burden/vulnerability.</p>
     </>
    )
  
}

export default EbmMapTxt
