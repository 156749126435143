import { createSlice } from '@reduxjs/toolkit';

const initState = {
    token: null,
    tokenData: null,
    mode: null,
    timestamp:null
};
const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('ejdh_app_state');
        if (serializedState === null) {
            return initState;
        }
        const state = JSON.parse(serializedState);
        return (state.auth) ? state.auth : initState;
    } catch (e) {
        return initState;
    }
};


const initialState = loadState();

export const authSlice = createSlice({
    name: '[auth]',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload.token;
            state.tokenData = action.payload.tokenData;
            state.mode = action.payload.mode;
            state.timestamp = new Date().getTime();
        },
        logout: (state) => {
            state.token = null;
            state.tokenData = null;
            state.mode = null;
        },
        updateUser: (state, action) => {
            state.tokenData = action.payload;
        },
        setTokenTimeStamp: (state, action) => {
            state.timestamp = action.payload;
        }
    },
});

export const { login, logout, updateUser } = authSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectTokenData = (state) => state.auth.tokenData;
export const selectUserEmail = (state) => state.auth.tokenData?.email;
export const selectUserMode = (state) => state.auth.mode;
export const selectTokenTimeStamp = (state) => state.auth.timestamp;

export default authSlice.reducer;
