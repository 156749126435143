import React, { useState, useEffect, useRef} from 'react';
import './ChemicalDropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CommonServoces from '../../../services/commonServices';
import { useDispatch, useSelector } from 'react-redux';
import { selectChemicalName, selectZipCode, setChemicalId, setChemicalName, setHasChemicals, setIschemicalsDDState } from '../../../redux/appSlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectTokenData } from '../../../redux/authSlice';


const ChemicalDropdown = () => {
  const zipCode$ = useSelector(selectZipCode);
  const userData$ = useSelector(selectTokenData);
  const [userData, setUserData] = useState(userData$);
  const { zipcode } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select Chemical');
  const [chemicalNames, setChemicalNames] = useState([])
  const chemicalName$ = useSelector(selectChemicalName);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (chemicalName$) {
      setSelectedValue(chemicalName$);
    }
  }, [chemicalName$]);
  useEffect(() => {
    dispatch(setIschemicalsDDState("loading"))
  }, []);
  useEffect(() => {
    dispatch(setIschemicalsDDState("loading"))
    console.log(userData.USER_TYPE)
    CommonServoces.getChemicalNames(zipCode$, userData.USER_TYPE)
      .then((response) => {
        if (response.error) {
          toast.error(`Data Error: ${response.error.code}: ${response.error.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(setHasChemicals(false));
          dispatch(setIschemicalsDDState("nodata"))
          return;
        }
        const names = response.data.map((chemical) => ({
          name: chemical.chemicalName,
          id: chemical.id
        }));
        setChemicalNames(names);
        dispatch(setHasChemicals(names.length > 0 ? true : false));
        dispatch(setIschemicalsDDState(names.length > 0 ? "hasdata" : "nodata"))
        if (names && names[0]) {
          dispatch(setChemicalId(names[0].id));
          dispatch(setChemicalName(names[0].name));
        } else {
          dispatch(setChemicalId(0));
          setSelectedValue('Select Chemical');
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setIschemicalsDDState("nodata"))
      });
  }, [zipCode$, zipcode]);

  const toggleDropdown = () => {
    if (chemicalNames.length > 0) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionSelect = (id, value) => {
    setSelectedValue(value);
    setIsOpen(false);
    dispatch(setChemicalId(id));
    dispatch(setChemicalName(value));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`dropdown-container ${chemicalNames.length === 0 && 'disabled'}`} style={{ textAlign: 'center' }}>
      <div className={`chemical-dropdown ${isOpen ? 'open' : ''} ${chemicalNames.length === 0 ? 'readonly' : ''}`} onClick={toggleDropdown}
        style={{ cursor: chemicalNames.length === 0 ? 'not-allowed' : 'pointer' }}>
        {selectedValue}
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronUp} className="dropdown-icon" style={{ color: 'white' }} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
        )}
      </div>

      {chemicalNames.length > 0 && isOpen && (<div className="dropdown-options-container">
        <div className="dropdown-options">
          {
            chemicalNames.map((chemical, index) => (
              <div key={index} className="option" onClick={() => handleOptionSelect(chemical.id, chemical.name)}>
                {chemical.name}
              </div>
            ))
          }
        </div>
      </div>)}
    </div>
  );
};

export default ChemicalDropdown;
