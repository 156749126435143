import WildComponent from "../modules/common/wildComponent/WildComponent";

const appRoutes = [
    {
        label: 'Dashboard',
        faIcon: null,
        matIcon: 'dashboard',
        path: 'dashboard',
        component: <WildComponent />,
        exact: true,
        type: 'menuItem'
    },
    {
        label: 'Data Resources',
        faIcon: null,
        matIcon: 'groups',
        path: '',
        type: 'treeMenu',
        submenus: [
            {
                label: 'Demography',
                faIcon: null,
                matIcon: <img src='../images/resource-icon1.png' alt='' />,
                path: 'https://www.census.gov/',
                 // component: <WildComponent />,
                exact: true,
                type: 'menuItem',
            },
            {
                label: 'Toxic Release Inventory',
                faIcon: null,
                matIcon: <img src='../images/resource-icon2.png' alt='' />,
                path: 'https://www.epa.gov/toxics-release-inventory-tri-program/tri-data-and-tools',
                 // component: <WildComponent />,
                
                type: 'menuItem'
            },
            {
                label: ' Climate and Economic Justice Screening Tool',
                faIcon: null,
                matIcon: <img src='../images/resource-icon5.png' alt='' />,
                path: 'https://screeningtool.geoplatform.gov/en/#3/33.47/-97.5',
                 // component: <WildComponent />,
                
                type: 'menuItem'
            },
            {
                label: 'Greenhouse Gases Release',
                faIcon: null,
                matIcon: <img src='../images/resource-icon2.png' alt='' />,
                path: 'https://www.epa.gov/ghgreporting',
                // component: <WildComponent />,
                type: 'menuItem'
            },
            {
                label: 'Environmental Justice Index',
                faIcon: null,
                matIcon: <img src='../images/resource-icon3.png' alt='' />,
                path: 'https://www.atsdr.cdc.gov/placeandhealth/eji/index.html',
                // component: <WildComponent />,
                type: 'menuItem'
            },
            {
                label: 'Social Vulnerability Index',
                faIcon: null,
                matIcon: <img src='../images/resource-icon3.png' alt='' />,
                path: 'https://www.atsdr.cdc.gov/placeandhealth/svi/index.html',
                // component: <WildComponent />,
                type: 'menuItem'
            },
            {
                label: 'Health Impact',
                faIcon: null,
                matIcon: <img src='../images/resource-icon4.png' alt='' />,
                path: 'https://pharos.habitablefuture.org/',
                // component: <WildComponent />,
                type: 'menuItem'
            }

        ]
    },
    {
        type: 'divider'
    },
    // {
    //     label: 'Saved Reports',
    //     faIcon: null,
    //     matIcon: 'bookmarks',
    //     path: 'saved-reports',
    //     component: <WildComponent />,
    //     exact: true,
    //     type: 'treeMenu',
    //     submenus: [
    //         // {
    //         //     label: 'Report 1',
    //         //     faIcon: null,
    //         //     matIcon: 'bookmark',
    //         //     path: 'report-1',
    //         //     component: <WildComponent />,
    //         //     type: 'menuItem'
    //         // },
    //         // {
    //         //     label: 'Report 2',
    //         //     faIcon: null,
    //         //     matIcon: 'bookmark',
    //         //     path: 'report-2',
    //         //     component: <WildComponent />,
    //         //     type: 'menuItem'
    //         // },
    //         // {
    //         //     label: 'Report 3',
    //         //     faIcon: null,
    //         //     matIcon: 'bookmark',
    //         //     path: 'report-3',
    //         //     component: <WildComponent />,
    //         //     type: 'menuItem'
    //         // },
    //     ]
    // },
    

];




export default appRoutes;