import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './authSlice';
import userReducer from './userSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        user: userReducer
    },
});

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('ejdh_app_state', serializedState);
        
    } catch (e) {
        // Ignore write errors;
    }
};

store.subscribe(() => {
    saveState(store.getState());
});
