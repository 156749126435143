import React from 'react';
import { MDBIcon, MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const aboutSection = () => {
    return (
        <>
            <div className="main-sec">
                <div className="container">
                    <div className="row ">

                    <div className="col-md-12 mt-4">
                          <MDBCarousel showIndicators showControls fade>
                            <MDBCarouselItem itemId={1}>
                                <img src='../images/abt-img-sl1.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={2}>
                                <img src='../images/abt-img-sl2.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={3}>
                                <img src='../images/abt-img-sl3.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={4}>
                                <img src='../images/abt-img-sl4.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={5}>
                                <img src='../images/abt-img-sl5.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={6}>
                                <img src='../images/abt-img-sl6.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={7}>
                                <img src='../images/abt-img-sl7.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={8}>
                                <img src='../images/abt-img-sl8.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={9}>
                                <img src='../images/abt-img-sl9.jpg' className='d-block w-100 shadow-3 rounded' alt='...' />
                            </MDBCarouselItem>
                            </MDBCarousel>
                            
                        </div> 
                        
                        <div className="col-md-12">
                            <div className="abt-info2">
                            <h3 className="abt-title">About the EJ Data Action Portal</h3>
                            <p>The <strong>Deep South Center for Environmental Justice</strong> designed the EJ Data Action Portal to provide accurate zip code-level data on pollution impacting our health, environment, and climate.</p>
                                <p>Our goal in providing this tool is to support community-driven solutions to harmful environmental conditions and severe climate risks. This portal is the first of its kind to be built from the recommendations of environmental justice communities.​</p>
                                <p>The <strong>EJ Data Action Portal</strong> interfaces with the Healthy Building Network’s PHAROS database of chemicals and their scientifically known effects on human health and our environment. ​</p>
                                <Link to="https://www.dscej.org/" target='_blank' className='btn-alink'>Read more <MDBIcon fas icon="angle-double-right" /></Link>
                                
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default aboutSection
